import { IonContent, IonPage, IonButton, IonLabel, useIonViewDidEnter, IonHeader, IonToolbar, IonTitle, IonImg, IonItem, IonFooter } from '@ionic/react';
import React from 'react';
import './PrimeiroAcesso.css';
import imgTopo from '../../../theme/icons/primeira_tela.png';
import imgTopo1 from './imgs/Logo_sem_texto.png';
import { useHistory } from 'react-router-dom';


const PrimeiroAcesso: React.FC = () => {
  const history = useHistory();


  useIonViewDidEnter(() => {
  });

  const logarEvent = () => {
    history.replace("/login");
  }

  const cadastrarEvent = () => {
    history.replace("/cadastro");
  }

  return (
    <IonPage>
      <IonContent>
        <IonItem lines='none'>
          <IonImg style={{ maxWidth: '100px', margin: '75px 5% 50px 5%' }} src={imgTopo1} />
        </IonItem>
        <IonItem lines='none'>
          <IonLabel className="ion-text-wrap"style={{ textAlign: 'left', padding: '0', margin: '0 5% 50px 5%', fontSize: '20px', color: '#333333' }}><b>Motorista: sua melhor versão começa agora</b></IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonLabel className="ion-text-wrap" style={{ margin: '0 5% 0 5%', color: '#333333', textAlign: 'justify', fontSize: '16px' }}>Sabemos de suas dificuldades na rua. Estamos aqui para ajudar e facilitar seu dia a dia nas cargas e descargas.</IonLabel>
        </IonItem>

        
      </IonContent>
      <IonFooter className="ion-no-border">
      <IonItem lines='none'>
          <IonButton style={{ padding: "0 5% 0 5%", margin: '40px 0 25px 0', fontWeight: 'bold', width: '100%' }} size='large' expand='block' fill='solid' color='primary' onClick={() => { logarEvent(); }}>Já tenho cadastro</IonButton>
        </IonItem>
        <IonItem lines='none'>
          <IonButton style={{ padding: "0 5% 0 5%", margin: '0 0 40px 0', fontWeight: 'bold', width: '100%' }} size='large' expand='block' fill="solid" color="tertiary" onClick={() => { cadastrarEvent(); }}>Fazer cadastro</IonButton>
        </IonItem>
      </IonFooter>
    </IonPage>
  );
};

export default PrimeiroAcesso;
