import { IonContent, IonPage, IonButton, IonToast, IonHeader, IonToolbar, IonTitle, IonFooter, IonListHeader, IonAvatar, IonList, IonItem, IonLabel, IonText, IonIcon, IonButtons, IonModal, useIonViewWillEnter, useIonViewDidEnter, IonNote, IonAlert } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Conta.css';
import '@capacitor-community/http';
import { deslogar, requestService } from '../../../../../Utils/Services';
import avatar from './imgs/avatar.svg'
import { albums, arrowBackCircleOutline, car, card, exit, help, home, information, informationCircleOutline, list, lockClosed, person, personCircle, personCircleOutline, search, statsChart, statsChartOutline, volumeMediumOutline, wallet } from 'ionicons/icons';
import { setupConfig } from '@ionic/react'

const Conta: React.FC = () => {

    const history = useHistory();
    const [showToast2, setShowToast2] = useState(false);

    useIonViewDidEnter(() => {
        document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={person} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{localStorage.getItem("nome") || "Conta"}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonListHeader>Geral</IonListHeader>
                <IonItem lines="full" button routerLink="/motorista/contaalt" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={home} />
                    <IonLabel>Meus dados</IonLabel>
                </IonItem>
                <IonItem lines="full" button routerLink="/motorista/placa" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={car} />
                    <IonLabel>Veículos</IonLabel>
                </IonItem>
                <IonItem lines="full" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={statsChart} />
                    <IonLabel>Relatórios</IonLabel>
                </IonItem>
                <IonListHeader hidden={true}>Assinatura</IonListHeader>
                <IonItem lines="full" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={card} />
                    <IonLabel>Forma de pagamento</IonLabel>
                </IonItem>
                <IonItem lines="full" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={albums} />
                    <IonLabel>Histórico de pagamentos</IonLabel>
                </IonItem>
                <IonListHeader>Informações</IonListHeader>
                <IonItem lines="full" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={help} />
                    <IonLabel>Ajuda</IonLabel>
                </IonItem>
                <IonItem lines="full" button routerLink="/motorista/sobre" routerDirection="forward" onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={information} />
                    <IonLabel>Sobre</IonLabel>
                </IonItem>
                <IonItem lines="full" button routerLink="/motorista/termospolitica" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={lockClosed} />
                    <IonLabel>Privacidade e termos de uso</IonLabel>
                </IonItem>
                <IonItem lines="full" button onClick={() => { setShowToast2(true); }} >
                    <IonIcon color='medium' slot="start" icon={exit} />
                    <IonLabel>Sair</IonLabel>
                    <IonAlert
                        isOpen={showToast2}
                        onDidDismiss={() => setShowToast2(false)}
                        cssClass='my-custom-class'
                        header={'Sair do Saidamax'}
                        message={'Deseja realmente <strong>desconectar</strong> de sua conta?'}
                        buttons={[
                            {
                                text: 'Cancelar',
                                role: 'cancel',
                            },
                            {
                                text: 'Sair',
                                cssClass: 'primary',
                                handler: () => {
                                    requestService(deslogar.url,{
                                        method: deslogar.method,
                                        headers: [["token", localStorage.getItem("token")||""]],
                                        credentials: 'include'
                                    },
                                    history,
                                    (response:any) => {
                                        //localStorage.clear();
                                        window.localStorage.setItem("cpf", "");
                                        window.localStorage.setItem("telefone", "");
                                        window.localStorage.setItem("token", "");
                                        window.localStorage.removeItem("watch");
                                        history.replace("/login/");
                                    },
                                    (error:any)=>{
                                        window.localStorage.setItem("cpf", "");
                                        window.localStorage.setItem("telefone", "");
                                        window.localStorage.setItem("token", "");
                                        window.localStorage.removeItem("watch");
                                        history.replace("/login/");
                                    });
                                }
                            }
                          ]}
                        />
                </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonItem lines="none">
                    <IonIcon color='medium' slot="start" icon={informationCircleOutline} />
                    <IonLabel>Versão</IonLabel>
                    <IonNote slot="end" className="footeritemnote">{window.localStorage.getItem("versao")}</IonNote>
                </IonItem>
            </IonFooter>
        </IonPage>
    );
};

export default Conta;
