import { IonContent, IonPage, IonButton, IonToast, IonHeader, IonToolbar, IonTitle, IonFooter, IonListHeader, IonAvatar, IonList, IonItem, IonLabel, IonText, IonIcon, IonButtons, IonModal, useIonViewWillEnter, useIonViewDidEnter, IonNote, IonAlert } from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import './SemPagamento.css';
import logo from './imgs/icon.png';
import { deslogar, requestService } from '../../../../../Utils/Services';

interface UserDetailPageProps extends RouteComponentProps<{ dias: string; }> { }

const SemPagamento: React.FC<UserDetailPageProps> = ({ match }) => {

  const history = useHistory();

  function logout() {
    requestService(deslogar.url, {
      method: deslogar.method,
      headers: [["token", localStorage.getItem("token") || ""]],
      credentials: 'include'
    },
      history,
      (response: any) => {
        //localStorage.clear();     
        window.localStorage.setItem("cpf", "");
        window.localStorage.setItem("telefone", "");
        window.localStorage.setItem("token", "");
      },
      (error: any) => {
        //localStorage.clear();
        window.localStorage.setItem("cpf", "");
        window.localStorage.setItem("telefone", "");
        window.localStorage.setItem("token", "");
      });
  }

  function redirecionar() {
    history.replace("/login/");
  }

  useIonViewDidEnter(() => {
    logout();
  });

  return (
    <IonPage>
      <IonContent fullscreen={true} className="semPagamento">
        <div slot="fixed" >
          <img src={logo} alt="Logo" width={"100px"} />
          <section></section>

          <section>
            <header><b>Período de avaliação encerrou</b></header>
            <IonLabel>Infelizmente seu período de avaliação gratuito encerrou!</IonLabel>
          </section>

          <section>
            <IonLabel>Acesse o site <a style={{ "color": "red" }} target='_blank' href={'https://saidamax.com.br'}>www.saidamax.com.br</a> para realizar sua assinuatura.</IonLabel>
          </section>

          <section></section>

          <IonButton color="primary" onClick={() => { redirecionar(); }}>OK</IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default SemPagamento;
