import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonIcon, IonHeader, IonToolbar, IonButtons, IonTitle, IonModal, IonItem, IonSegment, IonSegmentButton, IonFooter, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, isPlatform, IonText, } from '@ionic/react';
import React, { useRef, useState } from 'react';
import './Avulso.css';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { iniciarOperacaoAvulsa } from '../../../Utils/Services';
import '@capacitor-community/http';
import saidamax from './imgs/LogoCor.png';
import { alertCircle, arrowBack, arrowForwardCircle, checkmarkCircle, closeCircle, download, enter, exit, flag, leaf, mail, megaphone, megaphoneOutline, megaphoneSharp, push, sad, snow, thumbsUp } from 'ionicons/icons';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { BrowserMultiFormatReader } from '@zxing/library';

interface UserDetailPageProps extends RouteComponentProps<{ id: string; }> { }

//Variaveis para camera
let arrayDevices: any[] = []
let stream2: any;

const Avulso: React.FC<UserDetailPageProps> = ({ match }) => {
  const history = useHistory();

  //camera
  const [codeReader, setCodeReader] = React.useState(new BrowserMultiFormatReader());
  const [streamData, setStreamData] = useState<MediaStream>();
  const [front, setFront] = useState(false);

  //modais
  const [showPasso1, setShowPasso1] = useState(false);
  const [showPasso2, setShowPasso2] = useState(false);
  const [showPasso3, setShowPasso3] = useState(false);
  const [showPasso4, setShowPasso4] = useState(false);
  const [showPasso5, setShowPasso5] = useState(false);
  const [showPasso6, setShowPasso6] = useState(false);
  const [showPasso7, setShowPasso7] = useState(false);
  const [showPasso8, setShowPasso8] = useState(false);
  const [showPasso9, setShowPasso9] = useState(false);
  const [showPasso10, setShowPasso10] = useState(false);
  const [showPasso11, setShowPasso11] = useState(false);
  const [showBrowserCamera, setShowBrowserCamera] = useState(false);

  //variaveis da operacao
  const [operacaoTipoCarga, setOperacaoTipoCarga] = useState("");
  const [operacaoTipo, setOperacaoTipo] = useState("");
  const [operacaoNomeMotorista, setOperacaoNomeMotorista] = useState(window.localStorage.getItem("operacaoNomeMotorista") || "");
  const [operacaoCPF, setOperacaoCPF] = useState(window.localStorage.getItem("operacaoCPF") || "");
  const [operacaoPlacaVeiculo, setOperacaoPlacaVeiculo] = useState(window.localStorage.getItem("operacaoPlacaVeiculo") || "");
  const [operacaoTelefone, setOperacaoTelefone] = useState(window.localStorage.getItem("operacaoTelefone") || "");
  const [operacaoNota, setOperacaoNota] = useState("");

  //referencias de campos para setar o focus
  const nomeRef = useRef<any>(null);
  const cpfRef = useRef<any>(null);
  const placaRef = useRef<any>(null);
  const telefoneRef = useRef<any>(null);

  function onChangeCPF(e: any) {
    e.persist();
    e.preventDefault();

    let val = e.target.value;
    let cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    setOperacaoCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    // if (e.keyCode === ENTER_KEY_CODE) {
    //   e.target.value = e.target.value.substring(0, 15);
    //   setTelefone(e.target.value.substring(0, 15));
    //   cadastrar();
    // }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setOperacaoTelefone(e.target.value.substring(0, 15));
      return;
    }

    console.log(operacaoTelefone.length);
    console.log(e.target.value.length);

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (operacaoTelefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > operacaoTelefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (operacaoTelefone.length === 1 || operacaoTelefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (operacaoTelefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setOperacaoTelefone(form.substr(0, i));
  }

  async function iniciarOperacao() {

    const objectRequest = {
      nome: operacaoNomeMotorista,
      cpf: operacaoCPF.replace("-", "").replace(".", "").replace(".", ""),
      telefone: "55" + operacaoTelefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
      placa: operacaoPlacaVeiculo,
      clienteid: match.params.id,
      tipoOperacao: operacaoTipo,
      tipoCarga: operacaoTipoCarga,
      nota: operacaoNota
    };

    console.log(objectRequest);

    await fetch(iniciarOperacaoAvulsa.url,
      {
        method: iniciarOperacaoAvulsa.method,
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      }).then((res) => {
        if (res.status === 200) {
          setShowPasso9(true);
        }
        else {
          // caso já tenha operação aberta
          if (res.status === 400) {
            console.log(res.json().then((data) => {
              if (data.message === "Em andamento") {
                setShowPasso11(true);
              }
              else {
                setShowPasso10(true);
              }
            }).catch(() => {
              setShowPasso10(true);
            }));
          }
          else {
            setShowPasso10(true);
          }
        }
      }).catch((err) => {
        setShowPasso10(true);
      });
  }

  const openScanner = async () => {

    if (isPlatform("mobileweb") || isPlatform("desktop")) {
      setShowBrowserCamera(true);
    }
    else {

      const opt: BarcodeScannerOptions = {
        formats: 'CODE_128',
        prompt: 'Escaneie o codigo de barras'
      }

      const data: any = await BarcodeScanner.scan(opt);

      if (data !== null) {
        setOperacaoNota(data.text);
        setShowPasso4(true);
      }
    }
  };

  function cancelarScan() {
    if (codeReader) {
      codeReader.stopAsyncDecode();
      codeReader.stopContinuousDecode();
    }

    if (streamData) {
      streamData.getVideoTracks().forEach((valor: MediaStreamTrack) => {
        valor.stop();
      });
    }

  }

  function startCamera() {
    if (arrayDevices.length == 0) {
      console.log("loadDevices");
      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .then(getStream)
        .catch((err) => { console.log(err); });
    } else {
      getStream();
    }
  }

  function gotDevices(deviceInfos: any) {
    console.log("getDevices");
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === "videoinput") {
        arrayDevices.push(deviceInfo.deviceId);
      }
    }
  }

  function getStream() {
    if (stream2) {
      stream2.getTracks().forEach(function (track: any) {
        console.log("getTracks");
        track.stop();
      });
    }

    //document.getElementById('flip-button').onclick = function() { setFront(!front); };

    const constraints = {
      audio: false,
      video: {
        width: 1024,
        height: 576,
        facingMode: (front ? "user" : "environment")
        //facingMode: { exact: "environment" }

        //deviceId: { exact: arrayDevices[selectedCamera] },
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch((err) => { console.log(err) });
  }

  function gotStream(stream: MediaStream) {
    setStreamData(stream);
    console.log("gotStream");
    const videoElement = document.getElementById("video") as HTMLVideoElement;
    stream.getVideoTracks().forEach((valor: MediaStreamTrack) => { console.log(valor); })

    videoElement.srcObject = stream;
    codeReader.decodeFromStream(stream, videoElement, (result: any) => {
      if (result) {
        codeReader.stopAsyncDecode();
        codeReader.stopContinuousDecode();
        stream.getVideoTracks().forEach((valor: MediaStreamTrack) => {
          console.log(valor);
          valor.stop();
        });
        setOperacaoNota(result.text.toString());
        setShowBrowserCamera(false);
        setShowPasso4(true);
      } else {

      }
    }).catch((err) => { console.log(err) });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Saidamax</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" style={{ "textAlign": "center" }}>
        <IonCard style={{ "textAlign": "center" }} onClick={() => { setShowPasso1(true); }}>
          <IonCardHeader>
            <IonCardSubtitle></IonCardSubtitle>
            <IonCardTitle className="avulso">Iniciar Operação</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonIcon icon={megaphoneSharp} style={{ "fontSize": "100px" }} />
          </IonCardContent>
        </IonCard>

        <IonModal isOpen={showPasso1} onDidDismiss={() => { setShowPasso1(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => { setShowPasso1(false); }}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>Operação</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonCard style={{ "textAlign": "center" }} onClick={() => { setOperacaoTipo("Entrega"); setShowPasso2(true); }}>
              <IonCardHeader>
                <IonCardTitle className="avulso">ENTREGA</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={enter} style={{ "fontSize": "100px" }} />
              <IonCardContent>
                Clique para realizar entrega
              </IonCardContent>
            </IonCard>
            <IonCard style={{ "textAlign": "center" }} onClick={() => { setOperacaoTipo("Coleta"); setShowPasso4(true); setOperacaoTipoCarga(""); setOperacaoNota("0"); }}>
              <IonCardHeader>
                <IonCardTitle className="avulso">COLETA</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={exit} style={{ "fontSize": "100px" }} />
              <IonCardContent>
                Clique para realizar coleta
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso2} onDidDismiss={() => { setShowPasso2(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => { setShowPasso2(false); }}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>Tipo de Carga</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonCard style={{ "textAlign": "center" }} onClick={() => { setOperacaoTipoCarga("Seca"); setShowPasso3(true); }}>
              <IonCardHeader>
                <IonCardTitle className="avulso">SECA</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={leaf} style={{ "fontSize": "100px" }} />
              <IonCardContent>
                Clique para entrega de carga seca
              </IonCardContent>
            </IonCard>
            <IonCard style={{ "textAlign": "center" }} onClick={() => { setOperacaoTipoCarga("Perecível"); setShowPasso3(true); }}>
              <IonCardHeader>
                <IonCardTitle className="avulso">PERECÍVEL</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={snow} style={{ "fontSize": "100px" }} />
              <IonCardContent>
                Clique para entrega de carga perecível
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso3} onDidDismiss={() => { setShowPasso3(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => { setShowPasso3(false) }}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>Nota Fiscal</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonItem lines="none">
              <IonLabel>Vamos ler a nota fiscal</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap">
                Aponte a câmera do celular para o código de barras da nota fiscal
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap">
                Quando estiver pronto. Clique no botão para ler a nota.
              </IonLabel>
            </IonItem>
            <IonCard style={{ "textAlign": "center" }} onClick={() => { setShowPasso1(true); }}>
              <IonCardHeader>
                <IonCardSubtitle></IonCardSubtitle>
                <IonCardTitle className="avulso">NOTA OK</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={thumbsUp} style={{ "fontSize": "100px" }} />
              <IonCardContent>
                Clique para iniciar continuar
              </IonCardContent>
            </IonCard>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <IonButton onClick={() => { openScanner(); }} expand="block" type="submit">Ler nota</IonButton>
              <IonButton onClick={() => { setShowPasso4(true); setOperacaoNota("0"); }} fill="outline" expand="block" type="submit">Não consegui ler / Não tenho</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showBrowserCamera} onDidDismiss={() => { setShowBrowserCamera(false); cancelarScan(); }} cssClass='my-custom-class camera' onDidPresent={() => { startCamera() }}>
          <video hidden={!showBrowserCamera} style={{ display: "flex", flexDirection: "column" }} id="video" autoPlay muted playsInline></video>
          <IonLabel className="helper">Aponte a camera para o <b>código de barras</b> da nota fiscal.</IonLabel>
          {/*
          <IonItem>
            <IonLabel hidden={false}>{notaLida}</IonLabel>
          </IonItem>
          */}
          <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {/*<IonButton fill="clear" slot="end" color="tertiary" onClick={() => {registrarNotaFiscal("12345646545644252345324523452345432523452344324342654");}}>input</IonButton>*/}
            {/*<IonButton fill="solid" slot="end" color="tertiary" onClick={() => {cancelarScan();lerNotaFiscal();}}>Ler Chave de Acesso da Nota</IonButton>*/}
            <IonButton fill="solid" slot="end" color="tertiary" onClick={() => { setFront(!front); startCamera(); }}>Virar camera</IonButton>
            <IonButton fill="clear" slot="end" color="tertiary" onClick={() => { cancelarScan(); setShowBrowserCamera(false); }}>Cancelar</IonButton>
          </div>
        </IonModal>

        <IonModal isOpen={showPasso4} onDidPresent={() => { nomeRef.current.setFocus(); }} onDidDismiss={() => setShowPasso4(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => { setShowPasso4(false) }}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>Seu Nome</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonItem>
              <IonInput placeholder="Digite seu nome" ref={(ref) => nomeRef.current = ref} value={operacaoNomeMotorista} required maxlength={80} onIonInput={(e: any) => setOperacaoNomeMotorista(e.target.value)}> </IonInput>
            </IonItem>
            <IonCard style={{ "textAlign": "center" }} onClick={() => { window.localStorage.setItem("operacaoNomeMotorista", operacaoNomeMotorista); setShowPasso5(true); }}>
              <img src={arrowForwardCircle} width="100px" />
              <IonCardContent>
                Clique para continuar
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso5} onDidPresent={() => { cpfRef.current.setFocus(); }} onDidDismiss={() => setShowPasso5(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => { setShowPasso5(false) }}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>Seu CPF</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonItem>
              <IonInput placeholder="Digite seu CPF" ref={(ref) => cpfRef.current = ref} value={operacaoCPF} type="tel" required maxlength={14} onKeyUp={onChangeCPF}> </IonInput>
            </IonItem>
            <IonCard style={{ "textAlign": "center" }} onClick={() => { window.localStorage.setItem("operacaoCPF", operacaoCPF); setShowPasso6(true); }}>
              <img src={arrowForwardCircle} width="100px" />
              <IonCardContent>
                Clique para continuar
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso6} onDidPresent={() => { placaRef.current.setFocus(); }} onDidDismiss={() => setShowPasso6(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => { setShowPasso6(false) }}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>Placa do Veículo</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonItem>
              <IonInput ref={(ref) => placaRef.current = ref} value={operacaoPlacaVeiculo} maxlength={7} placeholder="Informe a placa do veículo" onIonInput={(e: any) => { setOperacaoPlacaVeiculo(e.target.value); }}></IonInput>
            </IonItem>
            <IonCard style={{ "textAlign": "center" }} onClick={() => { window.localStorage.setItem("operacaoPlacaVeiculo", operacaoPlacaVeiculo); setShowPasso7(true); }}>
              <img src={arrowForwardCircle} width="100px" />
              <IonCardContent>
                Clique para continuar
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso7} onDidPresent={() => { telefoneRef.current.setFocus(); }} onDidDismiss={() => setShowPasso7(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => { setShowPasso7(false) }}>
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>Telefone</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonItem>
              <IonInput ref={(ref) => telefoneRef.current = ref} type="tel" maxlength={15} placeholder="Informe seu telefone para ser chamado" required value={operacaoTelefone} onKeyUp={onKeyUpTelefone} />
            </IonItem>
            <IonCard style={{ "textAlign": "center" }} onClick={async () => { window.localStorage.setItem("operacaoTelefone", operacaoTelefone); setShowPasso8(true); await iniciarOperacao(); }}>
              <img src={arrowForwardCircle} width="100px" />
              <IonCardContent>
                Concluir
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso8} onDidDismiss={() => setShowPasso8(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Aguarde...</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap">Estamos inicando a operação e avisando o cliente.</IonLabel>
            </IonItem>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso9} onDidDismiss={() => setShowPasso9(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Sucesso!</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonCard style={{ "textAlign": "center" }} >
              <IonCardHeader>
                <IonCardSubtitle></IonCardSubtitle>
                <IonCardTitle className="avulso">Aguarde ser chamado por SMS</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={checkmarkCircle} color="primary" style={{ "font-size": "100px" }} />
              <IonCardContent>
                Você pode fechar esta página
              </IonCardContent>
            </IonCard>
            <div style={{ "textAlign": "center", "margin": "20px" }} onClick={() => { window.open("https://www.saidamax.com.br/motorista.html", "_self"); }} >
              <p>Enquanto aguarda, clique aqui e saiba mais sobre o Saidamax</p>
              <p><img src={saidamax} width="50px" /></p>
            </div>

          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso10} onDidDismiss={() => setShowPasso10(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Erro nosso!</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light">
            <IonCard style={{ "textAlign": "center" }} >
              <IonCardHeader>
                <IonCardSubtitle></IonCardSubtitle>
                <IonCardTitle className="avulso">Procure o conferente</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={closeCircle} style={{ "fontSize": "100px" }} />
              <IonCardContent>
                Fale que deu erro. Desta vez vai ser do jeito antigo.
              </IonCardContent>
            </IonCard>

            <IonCard style={{ "textAlign": "center" }} >
              <IonCardHeader>
                <IonIcon icon={sad} style={{ "fontSize": "100px" }} />
              </IonCardHeader>
              <IonCardContent>
                Pedimos desculpas! Já avisamos os técnicos para corrigir e melhorarmos.
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPasso11} onDidDismiss={() => setShowPasso11(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Já tem operação!</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="light" style={{ "textAlign": "center" }}>
            <IonCard style={{ "textAlign": "center" }} >
              <IonCardHeader>
                <IonCardSubtitle></IonCardSubtitle>
                <IonCardTitle className="avulso">Procure o conferente</IonCardTitle>
              </IonCardHeader>
              <IonIcon icon={alertCircle} style={{ "fontSize": "100px" }} />
              <IonCardContent>
                Fale desta mensagem e peça orientação
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>

        
      </IonContent>
      <IonFooter color="light" class="ion-no-border">
          <IonToolbar color="light">
            <IonItem color="light" lines="none" style={{ "textAlign": "center" }} >
              <IonLabel>www.saidamax.com.br</IonLabel>
            </IonItem>
          </IonToolbar>
        </IonFooter>
    </IonPage>
  );
};

export default Avulso;
