import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

import {
  Camera,
  Photo,
  CameraResultType,
  CameraSource,
  CameraDirection
} from "@capacitor/camera";

import { Filesystem, Directory } from "@capacitor/filesystem";

import S3 from 'react-aws-s3-typescript';
import imageCompression from 'browser-image-compression';
import { ambiente, incluirOcorrenciaOperacao, requestService } from '../Utils/Services';

//const { Filesystem, FilesystemDirectory } = Plugins;

const PHOTO_STORAGE = "saidamax";
export function usePhotoGallery(idOperacao: string, history: any) {

  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  const takePhoto = async (idOperacao: string) => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Camera,
      //quality: 80,
      preserveAspectRatio: true,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
      //saveToGallery: true,
      //width: 600,
      //height: 600
    }).then(async cameraPhoto => {

      var binary_string = window.atob(cameraPhoto.base64String || '');
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }

      const blob = new Blob([new Uint8Array(bytes.buffer)], {
        type: `image/${cameraPhoto.format}`,
      });

      const file = new File([blob], "Name", {
        lastModified: new Date().getTime(),
        type: blob.type,
      });


      // const resizeFile = (file: any) =>
      //   new Promise((resolve) => {
      //     Resizer.imageFileResizer(
      //       file,
      //       300,
      //       300,
      //       "PNG",
      //       100,
      //       0,
      //       (uri) => {
      //         resolve(uri);
      //       },
      //       "base64"
      //     );
      //   });

      // const resizeFile = (file: File) => new Promise(resolve => {
      //   Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      //     uri => {
      //       resolve(uri);
      //     }, 'base64');
      // });

      //const image: any = await resizeFile(file);

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1102,
        useWebWorker: true,
        initialQuality: 0.5
      }

      const optionsSmall = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 238,
        useWebWorker: true,
        initialQuality: 0.8
      }

      try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        const compressedFileSmall = await imageCompression(file, optionsSmall);

        const config = {
          bucketName: 'saidamax-motorista',
          dirName: ambiente + '/big',
          region: 'us-east-2',
          accessKeyId: 'AKIAVAPAJ3FJ5PH3DYSI',
          secretAccessKey: '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',
        };

        const configSmall = {
          bucketName: 'saidamax-motorista',
          dirName: ambiente + '/small',
          region: 'us-east-2',
          accessKeyId: 'AKIAVAPAJ3FJ5PH3DYSI',
          secretAccessKey: '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',
        };

        const fileName = new Date().getTime() + '';


        //console.log(image);
        console.log(fileName);
        const ReactS3ClientSmall = new S3(configSmall);
        ReactS3ClientSmall.uploadFile(compressedFileSmall, fileName).then((data: any) => {
          console.log(data);
          if (data.status === 204) {

            //console.log(cameraPhoto);

            const newPhotos = [{
              filepath: fileName,
              webviewpath: data.location//'https://saidamax-motorista.s3.us-east-2.amazonaws.com/small/' + fileName + '.png'
            }, ...photos];
            setPhotos(newPhotos);
            // console.log(photos);
            incluirOcorrencia(idOperacao, data.location);

            console.log("success");
          } else {
            console.log("fail");
          }
        });

        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(compressedFile, fileName).then((data: any) => {
          console.log(data);
          if (data.status === 204) {

            console.log("success");
          } else {
            console.log("fail");
          }
        });




      } catch (error) {
        console.log(error);
      }
    })
      .catch(error => {
        console.log(error);
      });


  };

  const loadPhotos = async (_photos: string[]) => {
    let newPhotos: UserPhoto[] = [];
    _photos.forEach(path => {
      console.log(path.toString());
      newPhotos.push({ filepath: path, webviewpath: path });
    });

    setPhotos(newPhotos);
  };

  const clearPhotos = async () => {
    let newPhotos: UserPhoto[] = [];
    setPhotos(newPhotos);
  };

  function incluirOcorrencia(operacaoId: string, path: string) {
    const atualizar = async () => {

      requestService(incluirOcorrenciaOperacao.url, {
        method: incluirOcorrenciaOperacao.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify({
          "id": operacaoId,
          "path": path
        }),
        credentials: 'include'
      },
        history
        ,
        (response: any) => {
          if (response.message) {
            console.log(response);
          }
        },
        (error: any) => {
          console.log(error);
          history.replace("/login")
        });
    }

    atualizar();

  };



  const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {

    let base64Data: string;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await Filesystem.readFile({
        path: photo.path!
      });
      base64Data = file.data;
    } else {
      base64Data = await base64FromPath(photo.webPath!);
    }
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents
    });
    console.log("arquivo gravado");
    console.log(fileName);
    console.log(base64Data);
    console.log(Directory.Documents);
    console.log(savedFile);

    const readedfile = await Filesystem.readFile({ path: savedFile.uri });
    console.log(savedFile);

    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      //return {
      //  filepath: savedFile.uri,
      //  webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      //};
    }
    else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        filepath: fileName,
        webviewpath: photo.webPath
      };
    }

    return {
      filepath: fileName,
      webviewpath: photo.webPath
    };
    //return {
    //  filepath: fileName,
    //  webviewPath: photo.webPath
    //     };
  };

  return {
    photos,
    takePhoto,
    loadPhotos,
    clearPhotos
  };

}

export interface UserPhoto {
  filepath: string;
  webviewpath?: string;
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string')
      }
    };
    reader.readAsDataURL(blob);
  });
}