import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonToast, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonCheckbox, IonIcon, isPlatform } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './Cadastro.css';
import imgTopo from './imgs/topo.png';
import { Link, useHistory } from 'react-router-dom';
import { requestService, usuarioCadastrar } from '../../../Utils/Services';
import { arrowBack, open, openOutline } from 'ionicons/icons';
import { Geolocation } from '@ionic-native/geolocation';

const Cadastro: React.FC = () => {

  const validator = require('validator');
  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;
  const history = useHistory();
  const campoTelefone = useRef<any>(null);
  const [nome, setNome] = useState("");
  const [cpf, setCPF] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [placa, setPlaca] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [checked, setChecked] = useState(false);
  const [checked90diasGratis, setChecked90diasGratis] = useState(true);

  const cadastrar = () => {

    let validadorCelular = /^((\+?55\ ?[1-9]{2}\ ?)|(\+?55\ ?\([1-9]{2}\)\ ?)|(0[1-9]{2}\ ?)|(\([1-9]{2}\)\ ?)|([1-9]{2}\ ?))((\d{4}\-?\d{4})|(9[1-9]{1}\d{3}\-?\d{4}))$/;

    if (nome.length < 3 || cpf.length != 14 || telefone.length != 15 || email.length < 3 || placa.length != 7) {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
    }
    else if (checked == false) {
      setTextToast("Você precisa aceitar os termos de uso e política de privacidade para efetuar o cadastro.");
      setShowToast(true);
    }
    else if (checked90diasGratis == false) {
      setTextToast("Você precisa aceitar os 90 dias grátis para efetuar o cadastro.");
      setShowToast(true);
    }
    else {
      if (!validarCpfNovo(cpf)) {
        setTextToast("CPF Inválido.");
        setShowToast(true);
      }
      /*
      else if (!validator.isMobilePhone(telefone, 'pt-BR')) {
        setTextToast("Celular Inválido.");
        setShowToast(true);
      }
      */
      else if (!validadorCelular.test(telefone)) {
        setTextToast("Celular Inválido.");
        setShowToast(true);
      }
      else if (!validator.isEmail(email)) {
        setTextToast("E-mail Inválido.");
        setShowToast(true);
      }
      else {

        const objectRequest = {
          nome: nome,
          cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
          telefone: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
          email: email,
          placa: placa,
          dispositivoIos: isPlatform('ios')
        }
        requestService(
          usuarioCadastrar.url,
          {
            method: usuarioCadastrar.method,
            body: JSON.stringify(objectRequest),
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              window.localStorage.setItem("cpf", cpf.replace("-", "").replace(".", "").replace(".", ""));
              window.localStorage.setItem("telefone", "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""));
              window.localStorage.setItem("veiculo", placa);
              window.localStorage.setItem("token", "token=" + response.token);
              // Geolocation.getCurrentPosition().then(
              //   (result) => {              
              //     window.localStorage.setItem("latitude", result.coords.latitude.toString());
              //     window.localStorage.setItem("longitude", result.coords.longitude.toString());
              //   }
              // );
              //history.replace("/validar-celular");
              history.push("/validar-celular");
            } else if (response.status === 400) {

            }
          },
          (error: any) => {
            console.log(error);
            setTextToast("Cadastro já existente");
            setShowToast(true);
          }
        )
      }
    }
  }

  function onChangeCPF(e: any) {

    //console.log(cpf.length);
    //console.log(e.target.value.length);
    e.persist();
    e.preventDefault();

    // if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
    //   e.target.value = e.target.value.substring(0, 14);
    //   setCPF(e.target.value.substring(0, 14));
    //   campoTelefone.current.setFocus();
    // }

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    // if (e.keyCode === ENTER_KEY_CODE) {
    //   e.target.value = e.target.value.substring(0, 15);
    //   setTelefone(e.target.value.substring(0, 15));
    //   cadastrar();
    // }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    console.log(telefone.length);
    console.log(e.target.value.length);

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }


  function validarCpfNovo(val: any): boolean {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else { return false; }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.replace("/primeiroacesso"); }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>CADASTRE-SE</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"Cadastro"}>
        <br/>
        <div className="input">
          <span>Nome</span>
          <IonInput value={nome} maxlength={50} placeholder="" onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
        </div>
        <div className="input">
          <span>CPF</span>
          <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} />
        </div>
        <div className="input">
          <span>Telefone</span>
          <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} />
        </div>
        <div className="input">
          <span>E-mail</span>
          <IonInput value={email} maxlength={50} type="email" placeholder="" onIonInput={(e: any) => setEmail(e.target.value)}></IonInput>
        </div>
        <div className="input">
          <span>Placa do Veículo</span>
          <IonInput value={placa} maxlength={7} placeholder="_______" onIonInput={(e: any) => setPlaca(e.target.value)}></IonInput>
        </div>

        <div className="input" style={{ display: 'block', alignItems: 'center', paddingTop: '30px' }}>
          <IonCheckbox style={{ 'margin-right': '8px' }} checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
          Eu li e aceito os&nbsp;<a style={{ "color": "red" }} target='_blank' href={'https://saidamax-plt-welcome.s3.us-east-2.amazonaws.com/termos/Termos_de_uso.pdf'}>Termos de Uso <IonIcon src={openOutline} /></a>&nbsp;e&nbsp;<a style={{ "color": "red" }} target='_blank' href={'https://saidamax-plt-welcome.s3.us-east-2.amazonaws.com/termos/politica_de_privacidade.pdf'}>Política de Privacidade <IonIcon src={openOutline} /></a>
        </div>
        <div hidden={true} className="input" style={{ display: 'block', alignItems: 'center', paddingTop: '30px' }}>
          <IonCheckbox style={{ 'margin-right': '8px' }} checked={checked90diasGratis} onIonChange={e => setChecked90diasGratis(e.detail.checked)} />
          Aceito ter 90 dias grátis do Saidamax
        </div>

        <IonButton onClick={cadastrar}>Cadastrar</IonButton>
        <IonLabel hidden={true} className="jaTem">Já tem uma conta? <Link to="/login">Entrar</Link></IonLabel>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={1000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Cadastro;
