import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonToast, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, useIonViewDidEnter, useIonViewWillEnter, isPlatform } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './ValidarLogin.css';
import imgTopo from './imgs/topo.png';
import { useHistory } from 'react-router-dom';
import { confirmarLogin, requestService } from '../../../Utils/Services';
import { arrowBack, documentOutline } from 'ionicons/icons';
import { authentication } from '../../../firebase-config';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";


const ValidarLogin: React.FC = () => {

  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(false);
  const [textBotao, setTextBotao] = useState("Validar Número");
  const [codigo, setCodigo] = useState("");
  const [confirmation, setConfirmation] = useState<any>();

  const inputRef = useRef<any>(null);

  useIonViewDidEnter(() => {

    var nome = 'divRecaptcha' + new Date().toLocaleTimeString();
    let elementToAdd = document.createElement("div");
    elementToAdd.setAttribute('id', nome);

    let div = document.getElementById('recaptcha-container');
    if (div) div.append(elementToAdd);

    if (!isPlatform('ios')) {
      let recpVerify = new RecaptchaVerifier(nome, {
        'size': 'invisible',
        'callback': (response: any) => {
        }
      }, authentication);

      signInWithPhoneNumber(authentication, '+' + window.localStorage.getItem("telefone"), recpVerify)
        .then((confirmationResult) => {
          setConfirmation(confirmationResult);
          inputRef.current.setFocus();
          setBotaoAtivo(true);
          //console.log(confirmationResult);
        }).catch((error) => {
          console.log(error);
          setTextToast("Não foi possível enviar o SMS.");
          setShowToast(true);
          setBotaoAtivo(true);
          history.goBack();
        });
    }
    else {
      inputRef.current.setFocus();
      setBotaoAtivo(true);
    }

    setCodigo("");
    setTextBotao("Validar Número");
  });

  const validar = () => {
    inputRef.current.setBlur();
    setBotaoAtivo(false);
    setTextBotao("Aguarde...");

    if (!isPlatform('ios')) {
      confirmation.confirm(codigo).then((result: any) => {
        console.log(result);
        const user = result?.user;

        login(user?.accessToken);


      }).catch((error: any) => {
        console.log(error);
        setTextToast("Token inválido");
        setShowToast(true);
        setCodigo("");
        setBotaoAtivo(true);
        setTextBotao("Validar Número");
      });
    }
    else {
      login();
    }
  }


  function login(accessToken?: any) {

    const objectRequest = {
      cpf: window.localStorage.getItem("cpf"),
      telefone: window.localStorage.getItem("telefone"),
      otp: parseInt(codigo),
      chaveContato: window.localStorage.getItem("chaveContato")?.toString() || "0",
      accessToken: accessToken
    }

    requestService(
      confirmarLogin.url,
      {
        method: confirmarLogin.method,
        headers: [
          ["token", window.localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      history,
      (response: any) => {
        window.localStorage.setItem("logado", "true");
        window.localStorage.setItem("mapaGoogle", response.mapaGoogle);

        if (response.placas) {
          window.localStorage.setItem("carros", JSON.stringify(response.placas));
          if (response.placas && response.placas[0]) {
            window.localStorage.setItem("veiculo", response.placas[0].toString());
          }
        }
        history.push("/avaliacaoapp");
      },
      (error: any) => {
        setTextToast("Token inválido");
        setShowToast(true);
        setCodigo("");
        setBotaoAtivo(true);
        setTextBotao("Validar Número");
      }
    );
  }

  function onChangeCodigo(e: any) {
    e.persist();
    e.preventDefault();
    setCodigo(e.target.value);

    if (e.keyCode === 13) {
      if (botaoAtivo) validar();
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle>VALIDE SEU CELULAR</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"ValidarLogin"}>
        <IonLabel className="titulo">Insira o <strong>código</strong> que você irá receber por <strong>SMS</strong> no celular informado:</IonLabel>
        <div className="input">
          <span>Token</span>
          <IonInput ref={(ref) => inputRef.current = ref} type="number" maxlength={6} required value={codigo} onKeyUp={onChangeCodigo} onIonInput={(e: any) => setCodigo(e.target.value)} />
        </div>

        <IonButton disabled={!botaoAtivo} onClick={() => { validar(); }}>{textBotao}</IonButton>
        <div id="recaptcha-container"></div>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={1000}
        />
      </IonContent>
    </IonPage>
  );
};

export default ValidarLogin;
