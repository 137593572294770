import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import Tesseract from 'tesseract.js';
import imageCompression from 'browser-image-compression';

import {
  Camera,
  Photo,
  CameraResultType,
  CameraSource,
  CameraDirection
} from "@capacitor/camera";

export function LeitorNotaFiscal() {

  const [notaLida, setNotaLida] = useState<any>();

  const doOCR = async (file: any) => {
    setNotaLida("Digitalizando nota fiscal...");
    Tesseract.recognize(
      file,
      'eng',
      { logger: m => {console.log(m);setNotaLida("Digitalizando nota fiscal... "+(m.progress*100).toString()+"%"); }}
    ).then(({ data: { text } }) => {
      let reg = /\d{44}/; //procura sequencia de 44 numeros
      let result = text.match(reg);
      
      //Se não encontrar, procura sequencia de 44 digitos seguidos
      if(!result){
        reg = /\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s/; //procura sequencia de 44 numeros
        result = text.match(reg);
      }

      // Dá erro caso nao encontre nenhuma das duas buscas
      if(!result){
        console.log("erro");
        setNotaLida("erro: tente melhorar a foto e aproximar mais dos números");
      }
      else{
        console.log(result[0]);
        setNotaLida(result[0]);
      }
    })
  };

  const lerNotaFiscal = async () => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Camera,
      //quality: 80,
      preserveAspectRatio: true,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
      //saveToGallery: true,
      //width: 600,
      //height: 600
    }).then(async cameraPhoto => {

      var binary_string = window.atob(cameraPhoto.base64String || '');
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }

      const blob = new Blob([new Uint8Array(bytes.buffer)], {
        type: `image/${cameraPhoto.format}`,
      });

      const file = new File([blob], "Name", {
        lastModified: new Date().getTime(),
        type: blob.type,
      });

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 500,
        useWebWorker: true,
        initialQuality: 0.5
      }

      const compressedFile = await imageCompression(file, options);

      await doOCR(compressedFile);
      return notaLida;
    })
  };

  return {
    notaLida,
    lerNotaFiscal
  };

}