import { IonToast, IonTabs, IonPage, IonTabBar, IonTabButton, IonProgressBar, IonIcon, IonLabel, IonBadge, IonRouterOutlet, IonTab, IonContent, IonAlert, useIonViewWillEnter, useIonViewDidLeave, useIonViewDidEnter } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';
import roteiro from './imgs/caminhao.svg';
import compartilhado from './imgs/compartilhado.svg';
import itinerario from './imgs/itinerario.svg';
import conta from './imgs/conta.svg';
import Roteiro from './pages/Roteiro/Roteiro';
import Conta from './pages/Conta/Conta';
import ContaAlt from './pages/ContaAlt/ContaAlt';
import TermosPolitica from './pages/TermosPolitica/TermosPolitica';
import Sobre from './pages/Sobre/Sobre';
import Placa from './pages/Placa/Placa';
import Compartilhado from './pages/Compartilhado/Compartilhado';
import Acompanhar from './pages/Acompanhar/Acompanhar';
import "./Home.css"
import Itinerario from './pages/Itinerario/Itinerario';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { LocalNotifications, ActionPerformed as LocalNotificationActionPerformed } from '@capacitor/local-notifications';
import { Toast } from "@capacitor/toast";
import { Network } from '@capacitor/network';
import { archive, cube, enter, flag, handRight, list, megaphone, paperPlane, person, play, scan, square, star } from 'ionicons/icons';
import PlacaCad from './pages/PlacaCad/PlacaCad';
import { getPlatforms, isPlatform, Platforms } from '@ionic/core';
import { listarEmpresa, requestService, usuarioAtualizarChaveContato } from '../../../Utils/Services';

const Home: React.FC<RouteComponentProps> = ({ match }) => {

  const nullEntry: any[] = []
  const [notifications, setnotifications] = useState(nullEntry);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const [showToastNotification, setShowToastNotification] = useState(false);
  const history = useHistory();

  /*
  useIonViewDidEnter(() => {

    let temWatch = window.localStorage.getItem("watch");

    console.log("temwatch:"+temWatch);
    if (!temWatch) {
      console.log("entrou");
      navigator.geolocation.getCurrentPosition(
        (result) => {
          console.log("deu result");
          window.localStorage.setItem("latitude", result.coords.latitude.toString());
          window.localStorage.setItem("longitude", result.coords.longitude.toString());
        },
        (rejected) => {
          console.log("nao deu result");
          setShowToastNotification(true);
          //Toast.show({ text: "Ative seu GPS", duration: "long", position: "center" })
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 5000
        }
      );
    }
  });
  */

  const atualizarChaveContato = async (token: any) => {
    console.log('--token--');
    console.log(token);
    requestService(usuarioAtualizarChaveContato.url, {
      method: usuarioAtualizarChaveContato.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify({
        "chaveContato": token.toString()
      }),
      credentials: 'include'
    },
      history,
      (response: any) => {
        window.localStorage.setItem("chaveContato", token.toString());
      },
      (error: any) => {
        //todo: verificar para tratar caso ocorra erro
        console.log(error);
      });
  }


  const register = () => {
    console.log('Initializing HomePage');

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        window.localStorage.setItem("chaveContato", token.value.toString());
        // if (window.localStorage.getItem("chaveContato") != token.value.toString())
        //   atualizarChaveContato(token.value.toString());
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])

        console.log('Push received: ' + JSON.stringify(notification));
        const randomId = Math.floor(Math.random() * 10000) + 1;
        setShowToast2(true);
        await LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title || '',
              body: notification.body || '',
              id: randomId,
              extra: {
                notification: notification
              }
            }
          ]
        });

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
      }
    );
  }


  const showToast = async (msg: string) => {
    await Toast.show({
      text: msg
    })
  }

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path={`${match.url}/roteiro`} component={Roteiro} exact />
        <Route path={`${match.url}/acompanhar/:estabelecimento/:codigoBarras/:operacaoid/:paginaPai`} component={Acompanhar} exact />
        <Route path={`${match.url}/itinerario`} component={Itinerario} exact />
        <Route path={`${match.url}/compartilhado`} component={Compartilhado} exact />
        <Route path={`${match.url}/conta`} component={Conta} exact />
        <Route path={`${match.url}/contaalt`} component={ContaAlt} exact />
        <Route path={`${match.url}/termospolitica`} component={TermosPolitica} exact />
        <Route path={`${match.url}/sobre`} component={Sobre} exact />
        <Route path={`${match.url}/placa`} component={Placa} exact />
        <Route exact path={match.url} render={() => <Redirect to={`${match.url}/roteiro`} />} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
        <IonTabButton href={`${match.url}/roteiro`} tab="roteiro">
          <IonIcon icon={flag || enter || megaphone || cube || play || scan}></IonIcon>
          <IonLabel>Operação</IonLabel>
        </IonTabButton>
        <IonTabButton href={`${match.url}/compartilhado`} tab="compartilhado">
          <IonIcon icon={handRight}></IonIcon>
          <IonLabel>Apoio</IonLabel>
        </IonTabButton>
        <IonTabButton href={`${match.url}/itinerario`} tab="itinerario">
          <IonIcon icon={list}></IonIcon>
          <IonLabel>Histórico</IonLabel>
        </IonTabButton>
        <IonTabButton href={`${match.url}/conta`} tab="conta">
          <IonIcon icon={person}></IonIcon>
          <IonLabel>Conta</IonLabel>
          <IonAlert
            isOpen={showToastNotification}
            onDidDismiss={() => setShowToastNotification(false)}
            cssClass='my-custom-class'
            header={'Aviso!'}
            subHeader={'Habilite seu GPS'}
            message={'Para encontrar a loja você precisa habilitar seu GPS.'}
            buttons={['OK']}
            backdropDismiss={false}
          />
          {/* <IonAlert
              isOpen={showToast2}
              onDidDismiss={() => setShowToast2(false)}
              cssClass='my-custom-class'
              header={'Aviso!'}
              subHeader={'Hora de realizar a operação'}
              message={'Diriga-se até a doca para realizar a carga/descarga!'}
              buttons={['OK']}
              backdropDismiss={false}
            /> */}
          {/*
          <IonAlert
            isOpen={showToast3}
            onDidDismiss={() => setShowToast3(false)}
            cssClass='my-custom-class'
            header={'Aviso!'}
            subHeader={'Sem internet'}
            message={'Você está sem acesso a internet, verifique os dados do celular ou rede wifi.'}
            buttons={['OK']}
            backdropDismiss={false}
          />*/}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Home;

