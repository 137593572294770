import { IonContent, IonPage, IonSlides, IonButton, IonSlide, IonLabel, IonSegment, IonSegmentButton, IonIcon, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonModal, useIonViewWillEnter, IonInfiniteScroll, IonInfiniteScrollContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonLoading, IonBadge, IonChip, useIonViewDidEnter } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { arrowBack, arrowBackCircleOutline, close, exit, handRight, list } from 'ionicons/icons';
import './Itinerario.css';
import { useHistory } from 'react-router-dom';
import iconVerified from "./imgs/certificado.svg";
import Acompanhar from '../Acompanhar/Acompanhar';
import { listaOperacao, requestService } from '../../../../../Utils/Services';

const Itinerario: React.FC = () => {

  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [ItinerarioPages, setItinerarioPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [carregado, setCarregado] = useState(false);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  const [operacaoId, setOperacaoId] = useState("");
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [operacaopk, setOperacaopk] = useState("");
  const [showLoading, setShowLoading] = useState(true);

  useIonViewDidEnter(() => {
    setChamadaRealizada(false);
    loadItens();
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
  });

  let slides = useRef(document.createElement('ion-slides'));

  const loadSlides = () => {
    if (carregado) {
      return (
        <IonSlides className={"ItinerarioSlides"} pager={true} ref={slides} onIonSlideWillChange={nextSlide}>
          {ItinerarioPages.map((value, index) => (<IonSlide key={index}>
            <h1>{value.titulo}</h1>
            <h2>{value.descricao}</h2>
            <img src={value.caminho} alt="img" />
          </IonSlide>))}
        </IonSlides>
      )
    }
  }

  const nextSlide = () => {
    slides.current.getActiveIndex().then(
      isEnd => {
        if (isEnd === 1)
          setAba("anteriores")
        else
          setAba("proximos")
      }
    );
  }

  function alteraAba(e: any) {
    let selected = e.detail.value;
    console.log('Segment selected', selected)
    if (selected === "anteriores") {
      slides.current.slideTo(1);
      setAba(selected);
    } else if (selected === "proximos") {
      slides.current.slideTo(0);
      setAba(selected);
    }
  }

  function pisca(valor: string) {
    if (valor === "Em andamento") return "pisca piscaverde";
    else return "pisca";
  }

  const loadItens = () => {

    if (!chamadaRealizada) {
      setChamadaRealizada(true);
      carregarOperacoes();
    }

    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader >
            <IonCardTitle>Aqui aparecem as operações de carga/descarga que você realiza</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      );
    }

    return arrayOperacoes.map((value: any, index: any, arr: any) =>
      //(new Date(value.dataInicio).toLocaleString('pt-BR').substring(0,10)===dia)?
      (index === 0 || (arr[index - 1] && new Date(value.dataInicio).toLocaleString('pt-BR').substring(0, 10) !== new Date(arr[index - 1].dataInicio).toLocaleString('pt-BR').substring(0, 10))) ?
        (<>
          <IonCard className={"card"} key={value.dataInicio + index.toString()}>
            <IonCardHeader key={value.dataInicio}>
              <IonCardTitle>
                <div className={"cabecalho"} >{new Date(value.dataInicio).toLocaleString('pt-BR').substring(0, 10) === new Date().toLocaleString('pt-BR').substring(0, 10) ? "Hoje" : new Date(value.dataInicio).toLocaleString('pt-BR').substring(0, 10)}</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          <IonCard key={"cartao" + value.id + index.toString()}>
            <IonCardHeader key={value.id}>
              <IonCardTitle>{value.clienteNome}</IonCardTitle>
              <IonCardSubtitle hidden={!value.clienteParceiroSm}>
                <IonBadge color="primary" >parceiro</IonBadge>
              </IonCardSubtitle>
              <IonCardSubtitle hidden={value.encerradorOperacao?false:true}>
                <IonChip color="danger">
                  <IonIcon icon={exit} ></IonIcon>
                  <IonLabel>Encerrado pelo cliente</IonLabel>
                </IonChip>
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>Operação: {value.tipoOperacao || "não identificada"}</IonLabel>
              <div>
                <IonLabel>Duração: {tempoCorrido(new Date(value.dataInicio), (value.dataEncerramento ? new Date(value.dataEncerramento) : new Date()))}</IonLabel>
              </div>
              <div>
                <IonLabel>Distância: {Math.round(value.distancia * 1.1 * 100) / 100 || "0"}KM</IonLabel>
              </div>
              <IonButton shape="round" fill='clear' slot="end" routerLink={"/motorista/acompanhar/"+value.id.replace(/#/g, '%23') + "/" + value.id.replace(/#/g, '%23') + "/" + value.id.replace(/#/g, '%23')+ "/itinerario"} routerDirection="forward" onClick={()=>{document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";}}>Ver Detalhes</IonButton>
              <div className="status">
                <IonLabel>{value.status}</IonLabel>
                <div className={pisca(value.status)}></div>
              </div>
            </IonCardContent>
          </IonCard>
        </>
        ) :
        (
          <IonCard key={"cartao" + value.id + index.toString()}>
            <IonCardHeader key={value.id}>
              <IonCardTitle><div></div>{value.clienteNome}</IonCardTitle>
              <IonCardSubtitle hidden={!value.clienteParceiroSm}>
                <IonBadge color="primary" >parceiro</IonBadge>
              </IonCardSubtitle>
              <IonCardSubtitle hidden={value.encerradorOperacao?false:true}>
                <IonChip color="danger">
                  <IonIcon icon={exit} ></IonIcon>
                  <IonLabel>Encerrado pelo cliente</IonLabel>
                </IonChip>
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>Operação: {value.tipoOperacao || "não identificada"}</IonLabel>
              <div>
                <IonLabel>Duração: {tempoCorrido(new Date(value.dataInicio), (value.dataEncerramento ? new Date(value.dataEncerramento) : new Date()))}</IonLabel>
              </div>
              <div>
                <IonLabel>Distância: {Math.round(value.distancia * 1.1 * 100) / 100 || "0"}KM</IonLabel>
              </div>
              <IonButton fill='clear' slot="end" routerLink={"/motorista/acompanhar/"+value.id.replace(/#/g, '%23') + "/" + value.id.replace(/#/g, '%23') + "/" + value.id.replace(/#/g, '%23')+ "/itinerario"} routerDirection="forward" onClick={()=>{document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";}}>Ver Detalhes</IonButton>
              
              <div className="status">
                <IonLabel>{value.status}</IonLabel>
                <div className={pisca(value.status)}></div>
              </div>
            </IonCardContent>
          </IonCard>
        )
    );

  }

  function carregarOperacoes() {
    const consultar = async () => {

      requestService(listaOperacao.url, {
        method: listaOperacao.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setArrayOperacoes(response.Items);
          if (response.LastEvaluatedKey) {
            setOperacaopk(response.LastEvaluatedKey.pk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setOperacaopk("");
            setDesabilitarInfiniteScroll(true);
          }
          setShowLoading(false);
        },
        (error: any) => {
          setShowLoading(false);
          if (error.status === 426) { // atualizar versao
            history.replace("/atualizar");
          }
          else if (error.status !== 408) {
            history.replace("/login/");
          }
        });
    }

    consultar();
  }

  async function carregarMaisOperacoes($event: CustomEvent<void>) {

    await carregarMaisOperacoesChamada().then(() => {
      console.log("terminou resultados");
    });
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisOperacoesChamada() {

    requestService(listaOperacao.url + "?pk=" + operacaopk, {
      method: listaOperacao.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        console.log(arrayOperacoes.length);
        for (let i = 0; i < response.Items.length; i++) {
          console.log("resultados");
          arrayOperacoes.push(response.Items[i]);
        }
        console.log(arrayOperacoes.length);
        if (response.LastEvaluatedKey) {
          setOperacaopk(response.LastEvaluatedKey.pk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setDesabilitarInfiniteScroll(true);
          console.log("finalizou");
        }
      },
      (error: any) => {
        console.log(error);
        if (error.status === 426) { // atualizar versao
          history.replace("/atualizar");
        }
        else if (error !== 408) {
          history.replace("/login/");
        }
      });
  }

  function tempoCorrido(horario: Date, horarioFim: Date) {
    var g = Math.ceil((horarioFim.getTime() - horario.getTime()) / 1000 / 60);
    return Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + 'min';
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={list} />
            </IonButton>
          </IonButtons>
          <IonTitle>Histórico</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"Itinerario"}>
        
        <IonSlides className={"ItinerarioSlides"} ref={slides} onIonSlideWillChange={nextSlide}>
          <IonSlide key="slide1">
            {loadItens()}
          </IonSlide>
        </IonSlides>
        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisOperacoes(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais operações..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
    </IonPage>
  );
};

export default Itinerario;
