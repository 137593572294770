import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonAvatar, IonIcon, IonImg, IonToast, IonAlert, useIonViewDidEnter, isPlatform, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './Login.css';
import imgTopo from './imgs/LogoCor.png';
import imgGoogle from './imgs/Google.svg';
import imgFacebook from './imgs/Facebook.svg';
import { Link, useHistory } from 'react-router-dom';
import { logar, requestService } from '../../../Utils/Services';
import '@capacitor-community/http';
import { HttpResponse } from '@capacitor-community/http';
import { Geolocation } from '@ionic-native/geolocation';
import { arrowBack } from 'ionicons/icons';


const Login: React.FC = () => {
  const history = useHistory();
  const [telefone, setTelefone] = useState("");
  const [cpf, setCPF] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Entrar");
  const [showAlertSemPagamento, setShowAlertSemPagamento] = useState(false);
  const campoCPF = useRef<any>(null);
  const campoTelefone = useRef<any>(null);
  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;

  useIonViewDidEnter(() => {
    setBotaoAtivo(true);
    setTextBotao("Entrar");
  });

  function onChangeCPF(e: any) {

    //console.log(cpf.length);
    //console.log(e.target.value.length);
    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      campoTelefone.current.setFocus();
    }

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    //console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {
    e.persist();
    e.preventDefault();


    if (e.keyCode === ENTER_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      logarEvent();
    }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let val = e.target.value;
    var telVal = val.replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
    //console.log(cpfval);
    setTelefone(telVal);
  }


  function onKeyUpTelefoneOld(e: any) {
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      logarEvent();
    }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    //console.log(telefone.length);
    //console.log(e.target.value.length);

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }

  const logarFacebook = () => {

  }

  const logarGoogle = () => {

  };

  const logarEvent = () => {
    if (botaoAtivo) {
      setBotaoAtivo(false);
      setTextBotao("Aguarde...");

      const objectRequest = {
        cpf: cpf.replace(/\-/g, "").replace(/\./g, ""),
        telefone: "55" + telefone.replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\-/g, ""),
        dispositivoIos: isPlatform('ios')
      }

      requestService(
        logar.url,
        {
          method: logar.method,
          body: JSON.stringify(objectRequest),
          credentials: 'include'
        },
        history,
        (response: any) => {
          if (response) {
            setCPF("");
            setTelefone("");
            //setBotaoAtivo(true);
            //setTextBotao("Entrar");

            console.log(response);
            // time difference
            var timeDiff = Math.abs(Date.now() - Date.parse(response.message.dataInicio));
            // days difference
            var diffDays = 90 - (Math.ceil(timeDiff / (1000 * 3600 * 24))); // difference

            console.log(diffDays);
            if (response.message.appPeriodoAvaliacao || response.message.assinaturaAtiva || diffDays >= 0) {
              console.log('Motorista efetuou pagamento ou esta em período de avaliação');
              window.localStorage.setItem("cpf", cpf.replace(/\-/g, "").replace(/\./g, ""));
              window.localStorage.setItem("telefone", "55" + telefone.replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\-/g, ""));
              window.localStorage.setItem("token", "token=" + response.token);
              // Geolocation.getCurrentPosition().then(
              //   (result) => {              
              //     window.localStorage.setItem("latitude", result.coords.latitude.toString());
              //     window.localStorage.setItem("longitude", result.coords.longitude.toString());
              //   }
              // );

              history.push("/validar-login");
            }
            else {
              console.log('Motorista não efetuou pagamento');
              setShowAlertSemPagamento(true);
            }
          } else if (response.status === 400) {

          }
        },
        (error: any) => {
          if (error.saidamax === 444) {
            setBotaoAtivo(true);
            setTextBotao("Entrar");
          }
          else {
            //console.log(error)
            setTextToast("CPF ou Telefone não cadastrados");
            setShowToast(true);
            setBotaoAtivo(true);
            setTextBotao("Entrar");
          }
        }
      )
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={()=>{history.replace("/primeiroacesso");}}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>ACESSE</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"Login"}>
        <IonLabel className="titulo">Digite seus dados para acessar</IonLabel>
        <div className="input">
          <span>CPF</span>
          <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onIonInput={(e: any) => setCPF(e.target.value)} />
        </div>
        <div className="input">
          <span>Telefone</span>
          <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onIonInput={(e: any) => setTelefone(e.target.value)} />
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => { logarEvent(); }}>{textBotao}</IonButton>

        
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={1000}
        />
        <IonAlert
          isOpen={showAlertSemPagamento}
          cssClass='my-custom-class'
          header={'Período de avaliação encerrou!'}
          subHeader={'Infelizmente seu período de avaliação gratuito encerrou. Para continuar tendo os benefícios do Saidamax, acesse o site www.saidamax.com.br para realizar sua assinuatura.'}
          message={''}
          buttons={[
            {
              text: 'OK',
              cssClass: 'primary',
              handler: () => {
                setShowAlertSemPagamento(false);
              }
            }
          ]}
          backdropDismiss={false}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
