import { IonContent, IonPage, IonFab, IonFabButton, IonIcon, IonLabel, IonModal, IonButton, IonList, IonItem, IonImg, IonTextarea, useIonViewDidEnter, IonSelect, IonSelectOption, IonItemDivider, IonHeader, IonToolbar, IonButtons, IonTitle, IonInput, IonAlert, IonFooter, IonLoading, IonNote, IonSegment, IonSegmentButton, IonBadge, isPlatform, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { addCircle, alertCircle, arrowBackCircleOutline, build, car, flag, leaf, snow, locateSharp, infinite } from 'ionicons/icons';
import './Roteiro.css';
import { useHistory } from 'react-router-dom';
import informar from './imgs/Informar.svg'
import { requestService, listarEmpresa, iniciarOperacao, listaOperacao, placaCadastrar } from '../../../../../Utils/Services';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import * as Leaflet from 'leaflet';
import { BrowserMultiFormatReader } from '@zxing/library';
import Iframe from "react-iframe"
import { Toast } from '@capacitor/toast';
import { App} from '@capacitor/app';

//Variaveis para camera
let arrayDevices: any[] = []
let stream2: any;

const Roteiro: React.FC = () => {

  const history = useHistory();
  const [position, setPosition] = React.useState({ lat: Number(localStorage.getItem("latitude") || "-23.550404108596"), lng: Number(localStorage.getItem("longitude") || "-46.633911201448136") });
  const [codigoBarras, setCodigoBarras] = React.useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalCodigoBarras, setShowModalCodigoBarras] = useState(false);
  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);
  const [showModalAguarde, setShowModalAguarde] = useState(false);
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState(-1);
  const [arrayEstabelecimentos, setArrayEstabelecimentos] = useState<any>([]);
  const [showModalTipoOperacao, setShowModalTipoOperacao] = useState(false);
  const [tipoOperacao, setTipoOperacao] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [codigoLido, setCodigoLido] = useState("");
  const [veiculo, setVeiculo] = useState(window.localStorage.getItem("veiculo") || "Não cadastrado");
  const [tipoCarga, setTipoCarga] = useState(window.localStorage.getItem("tipoCarga") || "Seca");
  const [showDetalhes2, setShowDetalhes2] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [placa, setPlaca] = useState("");
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading2, setShowLoading2] = useState(false);
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [nextToken, setNextToken] = useState("");
  const [showBrowserCamera, setShowBrowserCamera] = useState(false);
  const [mapaGoogle, setMapaGoogle] = useState(window.localStorage.getItem("mapaGoogle") === "true");
  const [leafletMap, setLeafletMap] = useState<any>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [showErroGPS, setShowErroGPS] = useState<boolean>(false);

  //camera
  const [codeReader, setCodeReader] = React.useState(new BrowserMultiFormatReader());
  const [streamData, setStreamData] = useState<MediaStream>();
  const [front, setFront] = useState(false);

  let mapa: any = null;
  let mark: any = null;
  let circle: any = null;
  let setView: boolean = true;

  const myIcon = Leaflet.icon({
    iconUrl: '/assets/Logo.svg',
    iconSize: [30, 30],
    popupAnchor: [0, -20]
  });

  useIonViewDidEnter(() => {
    setPageLoading(true);
    if (isPlatform('ios')) {
      App.addListener('appStateChange', ({ isActive }) => {
        // console.log('App state changed. Is active?', isActive);
        if (isActive) {
          console.log('console log reativado');// Do fun stuff.
          if (history.location.pathname.match("/motorista/roteiro") && !history.location.pathname.match("/motorista/acompanhar")) {
            setPageLoading(false);
            history.go(0);            
          }
        }
      });      
    }    
    verificarOperacaoEmAndamento2();
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
  });

  useIonViewDidLeave(() => {
    cleanPreviousMap();
  });

  function cleanPreviousMap() {
    if (mapa != null && mapa != undefined) {
      console.log("map stoped")
      mapa?.stopLocate();
      mapa?.remove();
      circle?.remove();
      mark?.remove();
      mapa = undefined;
      circle = undefined;
      mark = undefined;
      setView = true;
    }
  }

  function loadMap() {
    console.log("startMap");
    try {

      // link para os possiveis tratamentos de erro: https://stackoverflow.com/questions/19186428/refresh-leaflet-map-map-container-is-already-initialized
      cleanPreviousMap();

      mapa = Leaflet.map('mapId').fitWorld();
      setLeafletMap(mapa);

      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '© OpenStreetMap'
      }).addTo(mapa);

      mapa.on('locationfound', onLocationFound);

      mapa.on('locationerror', onLocationError);

      mapa.locate({ watch: true, setView: false, maxZoom: 17, enableHighAccuracy: true });
    }
    catch (e: any) {
      console.log("deu erro" + e.message);
      window.location.reload();      
    }
  }

  function onLocationFound(e: any) {
    console.log("localizacao encontrada:", e.latlng)
    // Raio de localização dos locais para realizar operação
    var radius = 100;//e.accuracy;

    let date = new Date();
    if (mark && mark != null && mark != undefined) {
      //mark.remove();
      mark.setLatLng(e.latlng);
      mark.setPopupContent("Entrega e Coleta em</br>locais até " + radius + " metros</br>" +
      ("0" + date.getDate()).substr(-2) +
      "/" + ("0" + (date.getMonth() + 1)).substr(-2) +
      "/" + date.getFullYear().toString().substr(-2) +
      " " + ("0" + date.getHours()).substr(-2) +
      ":" + ("0" + date.getMinutes()).substr(-2)
      , { closeButton: false });
    }
    else {
      console.log("mark", mark);


      mark = Leaflet.marker(e.latlng, { icon: myIcon }).addTo(mapa).bindPopup("Entrega e Coleta em</br>locais até " + radius + " metros</br>" +
        ("0" + date.getDate()).substr(-2) +
        "/" + ("0" + (date.getMonth() + 1)).substr(-2) +
        "/" + date.getFullYear().toString().substr(-2) +
        " " + ("0" + date.getHours()).substr(-2) +
        ":" + ("0" + date.getMinutes()).substr(-2)
        , { closeButton: false }).openPopup();
      setTimeout(() => {
        if (mark && mark != null && mark != undefined) {
          mark.closePopup();
        }
      }, 10000);
    }

    if (circle && circle != null && circle != undefined) {
      //circle.remove();
      circle.setLatLng(e.latlng);
    }
    else {
      console.log("circle", circle);
      circle = Leaflet.circle(e.latlng, radius).addTo(mapa);
    }

    if (setView) {
      mapa.setView(e.latlng, 17);
      setPageLoading(false);
      setView = false;
    }
    else {
      //mapa.setView(e.latlng, mapa.getZoom());
    }

    // Guarda as variaveis para abertura de operacao
    setPosition(e.latlng);
    window.localStorage.setItem("latitude", e.latlng.lat.toString());
    window.localStorage.setItem("longitude", e.latlng.lng.toString());
  }

  function onLocationError(e: any) {
    console.log("Saidamax erro de localizacao. Ative o GPS." + e.message);
    setPageLoading(false);
    if (e.code != 3) {
      setShowErroGPS(true);
    }
  }

  function centerMapLocation() {
    console.log("centerlocaiton", leafletMap, position);
    if (leafletMap != null || leafletMap != undefined) {
      leafletMap.setView(position, leafletMap.getZoom());
    }
    else {
      loadMap();
    }
  }

  function atualizaMapaGeolocationLeaflet() {
    centerMapLocation();
  }

  function atualizaMapaGeolocation() {
    navigator.geolocation.getCurrentPosition(
      (result) => {
        console.log('pegou geolocation');
        // if (Math.abs(Math.abs(result.coords.latitude) - Math.abs(window.latitude)) >= 0.0005 ||
        //   Math.abs(Math.abs(result.coords.longitude) - Math.abs(window.longitude)) >= 0.0005) {
        setPosition({
          lat: result.coords.latitude,
          lng: result.coords.longitude
        });

        window.localStorage.setItem("latitude", result.coords.latitude.toString());
        window.localStorage.setItem("longitude", result.coords.longitude.toString());

        // window.latitude = result.coords.latitude;
        // window.longitude = result.coords.longitude;
        //}
      },
      (rejected) => {
        console.log("reject getCurrentPosition", rejected);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 1000
      }
    );
  }

  const openScanner = async () => {

    if (isPlatform("mobileweb") || isPlatform("desktop")) {
      setShowBrowserCamera(true);
    }
    else {
      const opt: BarcodeScannerOptions = {
        formats: 'CODE_128',
        prompt: 'Escaneie o codigo de barras'
      }

      const data: any = await BarcodeScanner.scan(opt);

      if (data !== null) {
        setShowModalCodigoBarras(false);
        setShowModal(false);
        setCodigoBarras(data.text.match(/(.{1,4})/g).join(" "));
        setShowModalCodigoBarrasDigitar(true);
      }
    }
  };

  function atualizarPosicaoweb() {
    const atualizar = async () => {
      requestService(listarEmpresa.url + "?latitude=" + window.localStorage.getItem("latitude") + "&longitude=" + window.localStorage.getItem("longitude") + "&fonte=web&nexttoken=" + nextToken, {
        method: listarEmpresa.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setArrayEstabelecimentos(response.empresas);
          setNextToken(response.next_token);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        }).finally(() => { setShowLoading2(false); });
    }

    if (nextToken === '') {
      setShowLoading2(true);
      atualizar();
      loadItens();
    }
  }

  function carregarLocais() {
    const atualizarLocais = async () => {

      requestService(listarEmpresa.url + "?latitude=" + window.localStorage.getItem("latitude") + "&longitude=" + window.localStorage.getItem("longitude") + "&fonte=saidamax", {
        method: listarEmpresa.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setArrayEstabelecimentos(response.empresas);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        }).finally(() => { setShowLoading2(false); });
    }
    setShowLoading2(true);
    atualizarLocais();
    loadItens();
    setShowModal(true);
  }

  const loadItens = () => {


    if (arrayEstabelecimentos == undefined) return;

    if (nextToken === '') {
      if (!arrayEstabelecimentos.some((obj: any) => obj.nome === "BUSCAR MAIS LOCAIS")) {
        arrayEstabelecimentos.push({ nome: "BUSCAR MAIS LOCAIS", endereco: "Clique aqui para carregar mais locais." });
      }
    }
    else {
      if (!arrayEstabelecimentos.some((obj: any) => obj.nome === "NÃO ENCONTROU O LOCAL?")) {
        arrayEstabelecimentos.pop();
        arrayEstabelecimentos.push({ nome: "NÃO ENCONTROU O LOCAL?", endereco: "Você precisa estar com o GPS ativado e próximo ao local de entrega. Faça estes ajustes e consulte novamente para encontrar o local." });
      }
    }

    return arrayEstabelecimentos.map((value: any, index: any) => (
      <IonItem color={value.nome === "NÃO ENCONTROU O LOCAL?" ? "secondary" : undefined} style={{ "margin-bottom": "10px" }} lines="full" onClick={(e: any) => {
        setAlertaPreenchimento(false);
        setEstabelecimentoSelecionado(index);
        if (value.nome != undefined && value.nome == "BUSCAR MAIS LOCAIS") { atualizarPosicaoweb(); console.log("if"); }
        else if (value.nome != undefined && value.nome == "NÃO ENCONTROU O LOCAL?") { }
        else {
          if (tipoOperacao === "Entrega") {
            setShowModalCodigoBarrasDigitar(true);
          }
          else {
            setCodigoBarras("0");
            irAcompanhar((value.nome || value.clienteNome), (value.empresa || value.clienteCodigo), index.toString());
          }
        }
      }} key={index}>
        <IonLabel className="ion-text-wrap" style={{ "padding-bottom": "10px" }}>
          <IonBadge hidden={!value.clienteParceiroSm} color="primary" >Aqui tem Saidamax</IonBadge>
          <h2 style={{ "verticalAlign": "middle" }}><strong>{value.nome || value.clienteNome}</strong></h2>
          <h3>{value.endereco || value.clienteEndereco}</h3>
        </IonLabel>

        {/*loadStars(value.avaliacao || 0)*/}

      </IonItem>
    ));
  }

  function cancelarScan() {
    setShowModalCodigoBarrasDigitar(false);
    setShowModalCodigoBarras(false);
  }

  function cancelarScanWeb() {
    if (codeReader) {
      codeReader.stopAsyncDecode();
      codeReader.stopContinuousDecode();
    }

    if (streamData) {
      streamData.getVideoTracks().forEach((valor: MediaStreamTrack) => {
        valor.stop();
      });
    }
  }

  function modoCodigoBarras() {
    if (showModalCodigoBarras) {
      //Esta com escaneamento ativo
      setShowModalCodigoBarras(false);
      setShowModalCodigoBarrasDigitar(true);
    } else {
      setShowModalCodigoBarras(true);
      setShowModalCodigoBarrasDigitar(false);
    }
  }

  function irAcompanhar(codigoEstabelecimento: any, codigo: any, index?: any) {
    console.log("Ir acompanhar");
    if (tipoOperacao === "Entrega" && (codigoBarras === undefined || codigoBarras.replace(/ /g, '') === '' || codigoBarras.replace(/ /g, '').length != 44)) {
      console.log("if");
      setAlertaPreenchimento(true);
    }
    else {
      console.log("else");

      if (botaoAtivo) {
        console.log("if");
        setShowLoading2(true);
        setBotaoAtivo(false);
        //setShowModalCodigoBarrasDigitar(false);
        //setShowModalAguarde(true);

        let indice = index || estabelecimentoSelecionado;

        if (arrayEstabelecimentos[indice].sk != undefined) {
          console.log("if");
          requestService(iniciarOperacao.url, {
            method: iniciarOperacao.method,
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            body: JSON.stringify({
              "clienteid": arrayEstabelecimentos[indice].sk.substr(8),
              "latitude": arrayEstabelecimentos[indice].clienteLatitude,
              "longitude": arrayEstabelecimentos[indice].clienteLongitude,
              "nome": arrayEstabelecimentos[indice].clienteNome,
              "endereco": arrayEstabelecimentos[indice].clienteEndereco,
              "clientecodigo": arrayEstabelecimentos[indice].clienteCodigo,
              "chaveAcesso": index ? "0" : codigoBarras.replace(/ /g, ""),
              "tipoOperacao": tipoOperacao,
              "chaveContato": window.localStorage.getItem("chaveContato"),
              "veiculo": window.localStorage.getItem("veiculo") || "",
              "tipoCarga": tipoCarga
            }),
            credentials: 'include'
          },
            history,
            (response: any) => {
              if (response.message) {
                //stopWatchPosition();
                setShowModalCodigoBarrasDigitar(false);
                setShowModalCodigoBarras(false);
                setShowModal(false);
                setShowModalAguarde(false);
                setShowModalTipoOperacao(false);
                setShowBrowserCamera(false);
                setShowLoading2(false);
                history.push("/motorista/acompanhar/" + response.operacao.id.replace(/#/g, '%23') + "/" + response.operacao.id.replace(/#/g, '%23') + "/" + response.operacao.id.replace(/#/g, '%23') + "/roteiro");
                setBotaoAtivo(true);
              }
            },
            (error: any) => {
              //setShowModalAguarde(false);
              setBotaoAtivo(true);
              setShowLoading2(false);
            })
        }
        else {
          console.log("else");
          requestService(iniciarOperacao.url, {
            method: iniciarOperacao.method,
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            body: JSON.stringify({
              "latitude": arrayEstabelecimentos[indice].latitude,
              "longitude": arrayEstabelecimentos[indice].longitude,
              "nome": arrayEstabelecimentos[indice].nome,
              "endereco": arrayEstabelecimentos[indice].endereco,
              "clientecodigo": arrayEstabelecimentos[indice].empresa,
              "chaveAcesso": index ? "0" : codigoBarras.replace(/ /g, ""),
              "tipoOperacao": tipoOperacao,
              "chaveContato": window.localStorage.getItem("chaveContato"),
              "veiculo": window.localStorage.getItem("veiculo") || "",
              "tipoCarga": tipoCarga
            }),
            credentials: 'include'
          },
            history,
            (response: any) => {
              if (response.message) {
                //stopWatchPosition();
                setShowModalCodigoBarrasDigitar(false);
                setShowModalCodigoBarras(false);
                setShowModal(false);
                setShowModalAguarde(false);
                setShowModalTipoOperacao(false);
                setShowBrowserCamera(false);
                setShowLoading2(false);
                history.push("/motorista/acompanhar/" + response.operacao.id.replace(/#/g, '%23') + "/" + response.operacao.id.replace(/#/g, '%23') + "/" + response.operacao.id.replace(/#/g, '%23') + "/roteiro");
                setBotaoAtivo(true);

              }
            },
            (error: any) => {
              //setShowModalAguarde(false);
              setBotaoAtivo(true);
              setShowLoading2(false);
            })
        }
      }
    }
  }

  function verificarOperacaoEmAndamento2() {
    let myPromise = new Promise((myResolve, myReject) => {
      fetch(
        listaOperacao.url,
        {
          method: listaOperacao.method,
          headers: [
            ["token", window.localStorage.getItem("token") || ""],
            ["versao", window.localStorage.getItem("versao") || ""]
          ],
          credentials: 'include'
        }).then(res => {
          if (res.status === 426) {
            myReject(426);
            //history.replace("/atualizar");
          }
          else if (res.status === 401) {
            myReject(401);
            //history.replace("/login");
          }
          else if (res.status === 200 || res.status === 201) {
            res.json().then(response => {
              console.log(response);
              myResolve(response);
            }).catch(() => myReject(401));
          }
          else {
            myReject(401);
          };
        });
    }
    );

    myPromise.then(
      (value: any) => {

        if (value.Count > 0) {
          if (value.Items[0].status === "Em andamento") {
            console.log("verifica operacao em andamento", "replace historico");
            setPageLoading(false);
            history.push("/motorista/acompanhar/" + value.Items[0].clienteNome + "/123" + "/" + value.Items[0].id.replace(/#/g, '%23') + '/roteiro');
          }
          else {
            console.log("verifica operacao em andamento", "nao tem operacao em andamento");
            loadMap();
          }
        }
        else {
          console.log("verifica operacao em andamento", "nao tem operacoes");
          loadMap();
        }
      },
      (erro) => {
        Toast.show(
          {
            text: 'Erro: ' + erro,
            duration: "long"
          }
        );
      }
    )

  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };

  function readCars() {
    if (window.localStorage.getItem("carros")) {
      const carros = JSON.parse(window.localStorage.getItem("carros") || "");

      return carros.map((value: any, index: any, array: any) =>
      (
        <IonSelectOption key={value} value={value}>{value}</IonSelectOption>
      )
      )
    }
    else {
      window.localStorage.setItem("veiculo", "Não cadastrado");
      return (<IonSelectOption value="Não cadastrado">Não cadastrado</IonSelectOption>);
    }
  };

  function cadastrar() {
    let mensagem = '';

    if (placa.length != 7) {
      setTextToast("O campo Placa é obrigatório.");
      setShowErro(true);
    }
    else {
      setShowIncluirUsuario(true);
    }
  }

  function startCamera() {
    if (arrayDevices.length == 0) {
      console.log("loadDevices");
      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .then(getStream)
        .catch((err) => { console.log(err); });
    } else {
      getStream();
    }
  }

  function gotDevices(deviceInfos: any) {
    console.log("getDevices");
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === "videoinput") {
        arrayDevices.push(deviceInfo.deviceId);
      }
    }
  }

  function getStream() {
    if (stream2) {
      stream2.getTracks().forEach(function (track: any) {
        console.log("getTracks");
        track.stop();
      });
    }

    //document.getElementById('flip-button').onclick = function() { setFront(!front); };

    const constraints = {
      audio: false,
      video: {
        width: 1024,
        height: 576,
        facingMode: (front ? "user" : "environment")
        //facingMode: { exact: "environment" }

        //deviceId: { exact: arrayDevices[selectedCamera] },
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch((err) => { console.log(err) });
  }

  function gotStream(stream: MediaStream) {
    setStreamData(stream);
    console.log("gotStream");
    const videoElement = document.getElementById("video") as HTMLVideoElement;
    stream.getVideoTracks().forEach((valor: MediaStreamTrack) => { console.log(valor); })

    videoElement.srcObject = stream;
    codeReader.decodeFromStream(stream, videoElement, (result: any) => {
      if (result) {
        codeReader.stopAsyncDecode();
        codeReader.stopContinuousDecode();
        stream.getVideoTracks().forEach((valor: MediaStreamTrack) => {
          console.log(valor);
          valor.stop();
        });
        setShowBrowserCamera(false);
        setShowModalCodigoBarras(false);
        setShowModal(false);
        setCodigoBarras(result.text.toString().match(/(.{1,4})/g).join(" "));
        setShowModalCodigoBarrasDigitar(true);
      } else {

      }
    }).catch((err) => { console.log(err) });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={flag} />
            </IonButton>
          </IonButtons>
          <IonTitle>Operações</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"Roteiro"} >

        <div id="framemaps" style={{ pointerEvents: "none", position: "fixed", top: 0, left: 0, width: "100%", height: "105%", display: (mapaGoogle ? "block" : "none") }}>
          <Iframe key={0}
            url={"https://www.google.com/maps/embed/v1/view?key=AIzaSyDsbVscwNhxsgMynsga97Rea933LJLG5Jw&center=" + position.lat + "," + position.lng + "&zoom=15&maptype=roadmap"}
            width="100%"
            height="100%"
            id="myId"
            className="myClassname"
            display="inline"
            position="absolute"
            frameBorder={0}
            styles={{ border: "0" }}
          />
        </div>
        <div id="carrinho" style={{ position: "relative", width: "100%", height: "105%", display: (mapaGoogle ? "flex" : "none") }}>
          <img width={60} height={60} style={{ margin: "auto" }} src={"/assets/Logo.svg"}></img>
        </div>

        <IonFab style={{ top: 10, display: "flex" }} vertical="top" horizontal="end" slot="fixed" edge >
          <IonFabButton color="tertiary" onClick={() => { !mapaGoogle ? atualizaMapaGeolocationLeaflet() : atualizaMapaGeolocation(); }} >
            <IonIcon color="light" icon={locateSharp}></IonIcon>
          </IonFabButton>
        </IonFab>

        <div className={"mapId"} style={{ position: "relative", width: "100%", height: "100%", display: (mapaGoogle ? "none" : "block") }}>
          <IonImg src="Logo.png" hidden={true}></IonImg>
          <div id="mapId" style={{ width: "100%", height: "100%" }}></div>
          <IonLabel className={"labellatlng"} style={{ bottom: 20, position: "relative", fontSize: "10px" }}>{position.lat.toString().substr(0, 9) + ", " + position.lng.toString().substr(0, 9)}</IonLabel>
        </div>

        <div className="content">

          <IonModal isOpen={showModalCodigoBarrasDigitar} onDidDismiss={() => setShowModalCodigoBarrasDigitar(false)} cssClass='my-custom-class digitar'>
            <IonItem lines="none">
              <IonLabel className="helper">Informe a <b>chave de acesso</b><br />(código de barras) na <b>nota</b>:</IonLabel>


            </IonItem>

            <IonTextarea rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="clique aqui para digitar ou utilize o leitor de código de barras" onIonChange={e => space(e.detail.value!, 4)}></IonTextarea>
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} src={alertCircle} color="danger" slot="end" />
              <IonNote hidden={!alertaPreenchimento} color="danger" slot="end" style={{ "font-size": "14px" }} >Campo obrigatório</IonNote>
            </IonItem>
            <IonButton color="tertiary" onClick={() => { openScanner() }}>Leitor de Código de Barras </IonButton>
            <IonButton disabled={!botaoAtivo} onClick={() => { irAcompanhar((arrayEstabelecimentos[estabelecimentoSelecionado].nome || arrayEstabelecimentos[estabelecimentoSelecionado].clienteNome), codigoBarras) }}>Iniciar Operação!</IonButton>
            <IonButton fill="clear" slot="end" color="tertiary" onClick={cancelarScan}>Cancelar</IonButton>
          </IonModal>
          <IonModal isOpen={showModalCodigoBarras} onDidDismiss={() => setShowModalCodigoBarras(false)} cssClass='my-custom-class camera' >
            <IonLabel>{codigoLido}</IonLabel>
            <IonLabel className="helper">Precisamos que informe o <b>código de barras</b> da nota.</IonLabel>
            <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <IonButton color="primary" onClick={() => { openScanner() }}>Leitor de Código de Barras</IonButton>
              <IonButton color="primary" onClick={modoCodigoBarras}>Digitar o Código de Barras</IonButton>
              <IonButton fill="clear" slot="end" color="tertiary" onClick={cancelarScan}>Cancelar</IonButton>
            </div>
          </IonModal>
          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} cssClass='my-custom-class select-estabelecimento'>
            <p className="helper">Escolha o <b>local próximo</b> a você, onde irá realizar {tipoOperacao}.</p>
            <IonList>
              {loadItens()}
            </IonList>
            <IonButton color="primary" onClick={() => { setNextToken(''); setShowModal(false) }}>Cancelar</IonButton>
          </IonModal>
          <IonModal isOpen={showModalAguarde} onDidDismiss={() => setShowModalAguarde(false)} cssClass='my-custom-class aguarde'>
            <IonLabel className="helper">Aguarde a abertura da operação</IonLabel>
          </IonModal>
          <IonModal isOpen={showModalTipoOperacao} onDidDismiss={() => { setShowModalTipoOperacao(false) }} cssClass='my-custom-class tipooperacao'>
            <IonLabel className="helper"><b>Qual operação</b> você vai realizar?</IonLabel>

            <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>

              <IonItemDivider>
                <IonLabel>Tipo de Carga</IonLabel>
              </IonItemDivider>
              <IonItem lines="none">
                <IonSegment onIonChange={e => { window.localStorage.setItem("tipoCarga", e.detail.value || ""); setTipoCarga(e.detail.value || ""); }} value={tipoCarga}>
                  <IonSegmentButton value="Seca">
                    <IonLabel>Seca</IonLabel>
                    <IonIcon icon={leaf}></IonIcon>
                  </IonSegmentButton>
                  <IonSegmentButton value="Perecível">
                    <IonLabel>Perecível</IonLabel>
                    <IonIcon icon={snow} ></IonIcon>
                  </IonSegmentButton>
                </IonSegment>
              </IonItem>
              <IonItemDivider>
                <IonLabel>Veículo utilizado</IonLabel>
              </IonItemDivider>
              <IonItem lines="none">
                <IonButtons slot="start">
                  <IonButton onClick={() => { }}>
                    <IonIcon slot="icon-only" icon={car} />
                  </IonButton>
                </IonButtons>
                <IonSelect slot="start" value={veiculo} placeholder="Selecione" onIonChange={e => { window.localStorage.setItem("veiculo", e.detail.value); setVeiculo(e.detail.value); }}>
                  {readCars()}
                </IonSelect>
                <IonButtons slot="end" hidden={false}>
                  <IonButton onClick={() => { setShowDetalhes2(true); }}>
                    <IonIcon slot="icon-only" icon={addCircle || build} />
                  </IonButton>
                </IonButtons>
              </IonItem>

              <IonItemDivider>
                <IonLabel>Tipo de operação</IonLabel>
              </IonItemDivider>

              <IonButton color="primary" onClick={() => { setTipoOperacao("Entrega"); setCodigoBarras(""); carregarLocais(); }}>Entrega</IonButton>
              <IonButton color="primary" onClick={() => { setTipoOperacao("Coleta"); setCodigoBarras("0"); carregarLocais(); }}>Coleta</IonButton>
              <IonButton color="primary" onClick={() => { setTipoOperacao("Carregamento"); setCodigoBarras("0"); carregarLocais(); }}>Carregamento</IonButton>

              <div><br /></div>
              <IonButton fill="clear" slot="end" color="tertiary" onClick={() => { setShowModalTipoOperacao(false); }}>Cancelar</IonButton>
            </div>
          </IonModal>
          <IonModal isOpen={showDetalhes2} onDidDismiss={(e) => { e.stopPropagation(); setShowDetalhes2(false); }}>
            <IonIcon style={{ position: "absolute", top: "22pt", left: "10px", zIndex: 999, color: "var(--ion-color-cinza)" }} src={arrowBackCircleOutline} size="large" onClick={(e) => { e.stopPropagation(); setShowDetalhes2(false) }}></IonIcon>
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Inclusão de Veículo</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">Placa do veículo*</IonLabel>
                  <IonInput placeholder="Digite a placa do veículo" value={placa} required maxlength={7} onIonInput={(e: any) => setPlaca(e.target.value)}></IonInput>
                </IonItem>
              </IonList>

              <IonAlert isOpen={showIncluirUsuario}
                onDidDismiss={() => setShowIncluirUsuario(false)}
                header={'Cadastro de veículo'}
                message={'Você <strong>confirma</strong> inclusão?'}
                buttons={[
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                  },
                  {
                    text: 'Confirmar',
                    cssClass: 'primary',
                    handler: () => {

                      setShowLoading(true);

                      const objectRequest = {
                        placa: placa
                      }

                      let cpf = window.localStorage.getItem("cpf") + '';

                      requestService(placaCadastrar.url.replace("{cpf}", cpf), {
                        method: placaCadastrar.method,
                        body: JSON.stringify(objectRequest),
                        headers: [
                          ["token", window.localStorage.getItem("token")]
                        ],
                        credentials: 'include'
                      },
                        history,
                        (response: any) => {
                          if (response.placas) {
                            window.localStorage.setItem("carros", JSON.stringify(response.placas));
                          }
                          window.localStorage.setItem("veiculo", placa);
                          setVeiculo(placa);
                          setTextToast("Cadastro realizado com sucesso.");
                          setShowSucesso(true);
                          setShowLoading(false);
                        },
                        (error: any) => {
                          setTextToast(error.message);
                          setShowErro(true);
                          setShowLoading(false);
                        });
                    }
                  }
                ]}
              />

              <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => { setShowLoading(false); }}
                message={'Incluindo...'}

              />

              <IonLoading
                cssClass='my-custom-class'
                isOpen={showSucesso}
                onDidDismiss={(e) => { setShowSucesso(false); setPlaca(""); e.stopPropagation(); setShowDetalhes2(false); }}
                message={'Sucesso!'}
                duration={1200}
                animated={false}
              />

            </IonContent>
            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
          <IonModal isOpen={showBrowserCamera} onDidDismiss={() => { setShowBrowserCamera(false); cancelarScanWeb(); }} cssClass='my-custom-class camera' onDidPresent={() => { startCamera() }}>
            <video hidden={!showBrowserCamera} style={{ display: "flex", flexDirection: "column" }} id="video" autoPlay muted playsInline></video>
            <IonLabel className="helper">Aponte a camera para o <b>código de barras</b> da nota fiscal.</IonLabel>
            <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <IonButton fill="solid" slot="end" color="tertiary" onClick={() => { setFront(!front); startCamera(); }}>Virar camera</IonButton>
              <IonButton fill="clear" slot="end" color="tertiary" onClick={() => { cancelarScanWeb(); setShowBrowserCamera(false); }}>Cancelar</IonButton>
            </div>
          </IonModal>
        </div>
        <IonLoading
          isOpen={showLoading2}
          backdropDismiss={false}
          message={'Carregando...'}
        />
        <IonLoading
          isOpen={pageLoading}
          backdropDismiss={false}
          message={'Carregando...'}
        />
        <IonAlert
          isOpen={showErroGPS}
          onDidDismiss={() => { setShowErroGPS(false); }}
          backdropDismiss={false}
          cssClass='my-custom-class'
          header={'Ative o GPS'}
          message={'Não conseguimos encontrar sua localização. <strong>Ative seu GPS</strong> e clique em tentar novamente!'}
          buttons={[
            {
              text: 'Tentar novamente!',
              handler: () => {
                //history.push('/motorista/roteiro');
                window.location.reload();
              }
            }
          ]}
        />
      </IonContent>
      <IonFooter className="ionfooteriontoobarroteiro" onClick={() => {
        if (!mapaGoogle) {
          //atualizaMapaGeolocationLeaflet();
        }
        else {
          atualizaMapaGeolocation();
        }
        setNextToken("");
        setShowModalTipoOperacao(true);
      }}>
        <div className="action" >
          <IonLabel>Chegou no destino? Clique aqui para iniciar a operação.</IonLabel>
          <IonFab vertical="top" horizontal="end" slot="fixed">
            <IonFabButton >
              <IonIcon src={informar} size="large" />
            </IonFabButton>
          </IonFab>
        </div>
      </IonFooter>
      <IonAlert isOpen={showErro}
        onDidDismiss={() => setShowErro(false)}
        header={'Ops...'}
        message={textToast}
        buttons={['OK']}
      />
    </IonPage>
  );
};

export default Roteiro;