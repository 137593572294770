import React, { useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { IonApp, IonButton, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonModal, IonPage, IonRouterOutlet, IonTitle, IonToolbar, isPlatform, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './App.css';
import logo from './theme/icons/Logo.svg'
import logoTransparente from './theme/icons/Logo_transparente.png'
import { Geolocation } from '@ionic-native/geolocation';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Cadastro from './pages/Inicio/Cadastro/Cadastro';
import Onboarding from './pages/Inicio/Onboarding/Onboarding';
import Login from './pages/Inicio/Login/Login';
import PrimeiroAcesso from './pages/Inicio/PrimeiroAcesso/PrimeiroAcesso';

import Home from './pages/Motorista/Home/Home';
import ValidarCelular from './pages/Inicio/ValidarCelular/ValidarCelular';
import ValidarLogin from './pages/Inicio/ValidarLogin/ValidarLogin';
import Avulso from './pages/Inicio/Avulso/Avulso';
import { requestService, autenticado } from './Utils/Services';
import Atualizar from './pages/Inicio/Atualizar/Atualizar';
import PeriodoAvaliacao from './pages/Motorista/Home/pages/PeriodoAvaliacao/PeriodoAvaliacao';
import SemPagamento from './pages/Motorista/Home/pages/SemPagamento/SemPagamento';
import AvaliacaoAPP from './pages/Inicio/AvaliacaoAPP/AvaliacaoAPP';


import { Network } from '@capacitor/network';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { LocalNotificationActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import { Toast } from '@capacitor/toast';

class App extends React.Component<any, any>{


  constructor(props: any) {
    super(props);

    const nullEntry: any[] = [];

    // desebilita gesto de voltar passando de tela nos dispositivos que tenham ios
    if (isPlatform('ios')) {
      setupConfig({ swipeBackEnabled: false });
    }

    // versao do sistema
    localStorage.setItem("versao", '10712');
    // Push notification para celulares
    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      console.log("entrou chaveContato");
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {

            }
            else {
              console.log("entrou chaveContato 2");
              this.register();
            }
          });
        }
        else {
          console.log("entrou chaveContato 3");
          this.register();
        }
      });
    }
    else {
      //console.log("n entrou chaveContato");
      if (window.localStorage.getItem("chaveContato") != '0')
        window.localStorage.setItem("chaveContato", "0");//atualizarChaveContato('0');
    }

    // Local notification para celulares
    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      LocalNotifications.checkPermissions().then(
        callback => {
          if (callback.display == 'denied') {
            //setShowToastNotification(true);
          }
        });

      LocalNotifications.removeAllListeners();

      LocalNotifications.addListener("localNotificationActionPerformed",
        async (notification: LocalNotificationActionPerformed) => {
          await LocalNotifications.schedule({
            notifications: [
              {
                title: notification.notification.title || '',
                body: notification.notification.body || '',
                id: Math.floor(Math.random() * 10000) + 1,
                extra: {
                  notification: notification.notification
                }
              }
            ]
          });
        }
      );
    }

    if (window.location.href.includes("avulsas")) {
      this.state = {
        hasConnection: true,
        hasGeoLocation: true,
        loaded: true,
        destino: "/iniciar"
      };

      const tempo = 2500;

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

    }
    else {
      
      this.state = {
        hasConnection: true,
        hasGeoLocation: true,
        loaded: false,
        destino: "/"
      }

      const tempo = 2500;

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }
/*
      if ("geolocation" in navigator) {
        console.log("Available");
      } else {
        console.log("Not Available");
      }
      */

      
      Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000
      }).then(
        (result) => {
          this.setState({
            hasConnection: this.state.hasConnection,
            hasGeoLocation: true,
            loaded: this.state.loaded,//true,
            destino: this.state.destino//"/motorista/roteiro"
          });
          window.localStorage.setItem("latitude", result.coords.latitude.toString());
          window.localStorage.setItem("longitude", result.coords.longitude.toString());
        },
        undefined
      );
      


   
      // navigator.geolocation.watchPosition(
      //   (result) => {
      //     this.setState({
      //       hasConnection: this.state.hasConnection,
      //       hasGeoLocation: true,
      //       loaded: this.state.loaded,//true,
      //       destino: this.state.destino//"/motorista/roteiro"
      //     });      
      //     window.localStorage.setItem("latitude", result.coords.latitude.toString());
      //     window.localStorage.setItem("longitude", result.coords.longitude.toString());
      //   },
      //   undefined,
      //   {
      //     enableHighAccuracy: true,
      //     maximumAge: 0,
      //     timeout: Infinity
      //   })

      const objectRequest = {
        chaveContato: window.localStorage.getItem("chaveContato")?.toString() || "0"
      }

      window.localStorage.removeItem("watch");

      Network.addListener('networkStatusChange', status => {
        this.setState({
          hasConnection: status.connected,
          hasGeoLocation: this.state.hasGeoLocation,
          loaded: this.state.loaded,
          destino: this.state.destino,
        });
      });

      requestService(
        autenticado.url,
        {
          method: autenticado.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify(objectRequest),
          credentials: 'include'
        },
        this.props.history,
        (response: any) => {
          if (response.status === 200) {
            window.localStorage.setItem("mapaGoogle", response.message.mapaGoogle);
            //if (response.message.mapsource){
            //  window.localStorage.setItem("mapsource", response.message.mapsource.toString());
            //}

            if (response.message.appPeriodoAvaliacao) {
              setTimeout(() => {
                this.setState({
                  hasConnection: this.state.hasConnection,
                  hasGeoLocation: this.state.hasGeoLocation,
                  loaded: true,
                  destino: "/avaliacaoapp"
                });
              },
                tempo);
            }
            else {

              if (response.message.assinaturaAtiva) {
                setTimeout(() => {
                  this.setState({
                    hasConnection: this.state.hasConnection,
                    hasGeoLocation: this.state.hasGeoLocation,
                    loaded: true,
                    destino: "/motorista/roteiro"
                  });
                },
                  tempo);
              }
              else {

                // time difference
                var timeDiff = Math.abs(Date.now() - Date.parse(response.message.dataInicio));
                // days difference
                var diffDays = 90 - (Math.ceil(timeDiff / (1000 * 3600 * 24))); // difference

                if (diffDays < 0) {
                  setTimeout(() => {
                    this.setState({
                      hasConnection: this.state.hasConnection,
                      hasGeoLocation: this.state.hasGeoLocation,
                      loaded: true,
                      destino: "/sem-pagamento"
                    });
                  },
                    tempo);
                }
                else {
                  setTimeout(() => {
                    this.setState({
                      hasConnection: this.state.hasConnection,
                      hasGeoLocation: this.state.hasGeoLocation,
                      loaded: true,
                      destino: "/periodo-avaliacao/" + diffDays
                    });
                  },
                    tempo);
                }
              }
            }
          }

        },
        (error: any) => {
          if (error.status === 400) {
            setTimeout(() => {
              this.setState({
                hasConnection: this.state.hasConnection,
                hasGeoLocation: this.state.hasGeoLocation,
                loaded: true,
                destino: "/primeiroacesso"
              });
            },
              tempo);
          } else if (error.status === 401) {
            setTimeout(() => {
              this.setState({
                hasConnection: this.state.hasConnection,
                hasGeoLocation: this.state.hasGeoLocation,
                loaded: true,
                destino: "/primeiroacesso"
              });
            },
              tempo);
          } else if (error.saidamax === 444) {
            setTimeout(() => {
              this.setState({
                hasConnection: false,
                hasGeoLocation: this.state.hasGeoLocation,
                loaded: true,
                destino: "/avaliacaoapp"
              });
            },
              tempo);
          }
          else {
            setTimeout(() => {
              this.setState({
                hasConnection: this.state.hasConnection,
                hasGeoLocation: this.state.hasGeoLocation,
                loaded: true,
                destino: "/primeiroacesso"
              });
            },
              tempo);
          }
        },
        undefined,
        true
      );

      this.getSplash = this.getSplash.bind(this);

      const logCurrentNetworkStatus = async () => {
        const status = await Network.getStatus();
        this.setState({
          hasConnection: status.connected,
          hasGeoLocation: this.state.hasGeoLocation,
          loaded: this.state.loaded,
          destino: this.state.destino,
        });
      };
    }
  }

  showToast = async (msg: string) => {
    await Toast.show({
      text: msg
    })
  }

  register = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();
    console.log("entrou chaveContato 4");

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {

        console.log('tokenchavecontato:' + token.value.toString());
        window.localStorage.setItem("chaveContato", token.value.toString());
        // if (window.localStorage.getItem("chaveContato") != token.value.toString())
        //   atualizarChaveContato(token.value.toString());
      }
    );

    console.log("entrou chaveContato 5");
    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        //this.showToast("erro ao registrar token");
        //alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    console.log("entrou chaveContato 6");

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        //setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
        const randomId = Math.floor(Math.random() * 10000) + 1;
        //setShowToast2(true);
        await LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title || '',
              body: notification.body || '',
              id: randomId,
              extra: {
                notification: notification
              }
            }
          ]
        });

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        //setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
      }
    );
  }

  getSplash() {
    if (!window.location.href.includes("avulsas") &&
      !window.location.href.includes("atualizar")) {
      if (!this.state.loaded) {
        return (<IonPage>
          <IonContent className="Intro">
            <div className={"background-intro"}></div>
            <IonImg src={logoTransparente}></IonImg>

          </IonContent>
        </IonPage>);
      } else {
        return (<Redirect to={this.state.destino} />);
      }
    }

  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/motorista" component={Home} />
            <Route path="/onboarding" component={Onboarding} exact={true} />
            <Route path="/primeiroacesso" component={PrimeiroAcesso} exact={true} />
            <Route path="/login" component={Login} exact={true} />
            <Route path="/cadastro" component={Cadastro} exact={true} />
            <Route path="/validar-celular" component={ValidarCelular} exact={true} />
            <Route path="/validar-login" component={ValidarLogin} exact={true} />
            <Route path="/avulsas/:id" component={Avulso} />
            <Route path="/atualizar" component={Atualizar} exact={true} />

            <Route path="/periodo-avaliacao/:dias" component={PeriodoAvaliacao} />
            <Route path="/sem-pagamento" component={SemPagamento} exact={true} />
            <Route path="/avaliacaoapp" component={AvaliacaoAPP} exact={true} />


          </IonRouterOutlet>

          {this.getSplash()}
          <IonPage hidden={this.state.hasConnection && this.state.hasGeoLocation} color="primary">
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>OPS...</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonItem style={{ "paddingTop": "10px", "paddingBottom": "10px" }} lines='full' hidden={this.state.hasConnection}>
                <IonLabel className="ion-text-wrap" >Parece que você está sem conexão com a internet.</IonLabel>
              </IonItem>
              <IonItem style={{ "paddingTop": "10px", "paddingBottom": "10px" }} lines='full' hidden={this.state.hasGeoLocation}>
                <IonLabel className="ion-text-wrap" >Você precisa habilitar a localização de seu dispositivo para utilizar o Saidamax.</IonLabel>
                <IonButton onClick={() => {
                  Geolocation.getCurrentPosition({
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: 5000
                  }).then(
                    (result) => {
                      //console.log(result);
                      this.setState({
                        hasConnection: this.state.hasConnection,
                        hasGeoLocation: true,
                        loaded: this.state.loaded,//true,
                        destino: this.state.destino//"/motorista/roteiro"
                      });                      
                      window.localStorage.setItem("latitude", result.coords.latitude.toString());
                      window.localStorage.setItem("longitude", result.coords.longitude.toString());
                      console.log('gps', result);
                    }
                  )
                }}>Ativei</IonButton>
              </IonItem>
            </IonContent>
          </IonPage>

        </IonReactRouter>

      </IonApp>
    );
  }
}

export default App;
