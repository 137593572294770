import { IonContent, IonPage, IonButton, IonToast, IonLabel, IonInput, IonHeader, IonList, IonItem, IonToolbar, IonTitle, IonFooter, IonButtons, IonIcon, useIonViewWillEnter, IonBackButton, useIonViewWillLeave, useIonViewDidLeave, useIonViewDidEnter, IonLoading, IonAlert } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ContaAlt.css';
import '@capacitor-community/http';
import { usuarioCarregar, requestService, usuarioAlterar } from '../../../../../Utils/Services';
import { arrowBack, logoWindows } from 'ionicons/icons';
import validator from 'validator';

const ContaAlt: React.FC = () => {

  const history = useHistory();

  const [showToast2, setShowToast2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const campoTelefone = useRef<any>(null);
  const [selected, setSelected] = useState("Usuário");
  const [chamarServico, setChamarServico] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showSucesso, setShowSucesso] = useState(false);

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";

  });

  function carregarOperacoes() {

    const consultar = async () => {

      console.log("passou aqui");

      requestService(usuarioCarregar.url, {
        method: usuarioCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setNome(response.nome);
          formatCPF(response.id);
          formatTelefone(response.telefone.replace("55", ""));
          setEmail(response.email);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    if (chamarServico) {
      setChamarServico(false);
      consultar();
    }
  }

  const cadastrar = () => {

    if (nome.length < 3 || cpf.length != 14 || telefone.length != 15 || email.length < 3) {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
    }
    else if (cpf.replace("-", "").replace(".", "").replace(".", "") != window.localStorage.getItem("cpf")) {
      setTextToast("Não é possível alterar o CPF.");
      setShowToast(true);
    }
    else {
      if (!validarCpfNovo(cpf)) {
        setTextToast("CPF Inválido.");
        setShowToast(true);
      }
      else if (!validator.isMobilePhone(telefone, 'pt-BR')) {
        setTextToast("Celular Inválido.");
        setShowToast(true);
      }
      else if (!validator.isEmail(email)) {
        setTextToast("E-mail Inválido.");
        setShowToast(true);
      }
      else {
        setShowToast2(true);

      }
    }
  }


  function onChangeCPF(e: any) {

    e.persist();
    e.preventDefault();

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    formatCPF(e.target.value);
  }

  function formatCPF(e: string) {
    var cpfval = e.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    console.log(telefone.length);
    console.log(e.target.value.length);

    formatTelefone(e.target.value);

  }

  function formatTelefone(e: string) {
    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }


  function validarCpfNovo(val: any): boolean {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else { return false; }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text='' ></IonBackButton>
          </IonButtons>
          <IonTitle>Meus Dados</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {carregarOperacoes()}
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Nome*</IonLabel>
            <IonInput value={nome} maxlength={50} placeholder="Nome completo" onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">CPF* (Não é possível alterar)</IonLabel>
            <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} readonly />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Telefone*</IonLabel>
            <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">E-mail*</IonLabel>
            <IonInput value={email} maxlength={50} type="email" placeholder="Email para contato" onIonInput={(e: any) => setEmail(e.target.value)}></IonInput>
          </IonItem>
        </IonList>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={6000}
        />

        <IonAlert isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          header={'Alteração de cadastro'}
          message={'Você <strong>confirma</strong> a alteração?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                const objectRequest = {
                  nome: nome,
                  telefone: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
                  email: email
                }
                requestService(
                  usuarioAlterar.url,
                  {
                    method: usuarioAlterar.method,
                    body: JSON.stringify(objectRequest),
                    credentials: 'include'
                  },
                  history,
                  (response: any) => {
                    console.log(response);
                    if (response) {

                      window.localStorage.setItem("telefone", "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""));

                      setTextToast("Alteração realizado com sucesso.");
                      setShowSucesso(true);

                      //history.replace("/motorista/conta");

                    } else if (response.status === 400) {
                      console.log(response);
                      setTextToast("Ocorreu um erro ao efetuar a alteração.");
                      setShowToast(true);

                    }
                    setShowLoading(false);
                  },
                  (error: any) => {
                    console.log(error);
                    setTextToast("Ocorreu um erro ao efetuar a alteração.");
                    setShowToast(true);
                    setShowLoading(false);
                  })
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); history.replace("/motorista/conta"); }}
          message={'Sucesso!'}
          duration={1200}
          animated={false}
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit">Salvar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ContaAlt;
