import { IonContent, IonPage, IonButton, IonToast, IonHeader, IonToolbar, IonTitle, IonFooter, IonListHeader, IonAvatar, IonList, IonItem, IonLabel, IonText, IonIcon, IonButtons, IonModal, useIonViewWillEnter, useIonViewDidEnter, IonNote, IonAlert } from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import './PeriodoAvaliacao.css';
import logo from './imgs/icon.png';

interface UserDetailPageProps extends RouteComponentProps<{ dias: string; }> { }

const PeriodoAvaliacao: React.FC<UserDetailPageProps> = ({ match }) => {

  const history = useHistory();

  return (
    <IonPage>
      <IonContent fullscreen={true} className="periodoAvaliacao">
        <div slot="fixed" >
          <img src={logo} alt="Logo" width={"100px"} />
          <section></section>

          <section>
            <header><b>Período de avaliação</b></header>
            <IonLabel>Fique atento para não deixar de utilizar o Saidamax!</IonLabel>
          </section>

          <section>
            <IonLabel>Você tem mais <b>{match.params.dias} dias</b> de avaliação.</IonLabel>
          </section>

          <section></section>

          <IonButton color="primary" onClick={() => { history.replace("/motorista/roteiro"); }}>OK</IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default PeriodoAvaliacao;
