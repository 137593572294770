import { IonContent, IonPage, IonHeader, IonLabel, IonButton, IonIcon, IonList, IonListHeader, IonItem, IonAvatar, IonModal, IonTextarea, useIonViewDidEnter, IonImg, IonFab, IonFabButton, IonGrid, IonRow, IonCol, IonFooter, IonToolbar, IonActionSheet, IonTitle, IonButtons, IonItemDivider, IonLoading, IonNote, IonBadge, IonInput, isPlatform, IonAlert, IonBackButton, useIonViewWillEnter, useIonViewDidLeave } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import "./Acompanhar.css";
import iconVerified from "./imgs/certificado.svg";
import iconTempo from "./imgs/tempo.svg";
import iconFlag from "./imgs/flag.svg";
import iconCompartilhar from "./imgs/compartilhar.svg";
import iconHorario from "./imgs/horario.svg";
import iconLocal from "./imgs/local.svg";
import iconNotaFiscal from "./imgs/notafiscal.svg";
import { addCircle, alert, alertCircle, arrowBack, camera, cardOutline, caretForwardCircle, carOutline, checkmark, checkmarkCircle, close, closeOutline, compass, cube, cubeOutline, eye, flag, flagOutline, heart, hourglassOutline, information, informationCircle, informationCircleOutline, leafOutline, linkOutline, location, locationOutline, navigate, options, personOutline, pin, receipt, receiptOutline, shareOutline, star, starHalf, starOutline, thumbsUp, thumbsUpOutline, time, timeOutline, trash } from 'ionicons/icons';
import { carregaOperacao, clienteCarregar, encerrarOperacao, incluiNotaFiscal, requestService } from '../../../../../Utils/Services';
import { usePhotoGallery } from '../../../../../components/usePhotoGallery';
import { LeitorNotaFiscal } from '../../../../../components/LeitorNotaFiscal';
import BeautyStars from "beauty-stars";
import maps from './imgs/maps.png';
import waze from './imgs/waze.png';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType, Result } from '@zxing/library'
import { Toast } from '@capacitor/toast';

type Props = {
  estabelecimento: string;
  codigoBarras: string;
  operacaoid: string;
  paginaPai: string;
};

let stream2: any;
let arrayDevices: any[] = []

const Acompanhar: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { photos, takePhoto, loadPhotos, clearPhotos } = usePhotoGallery(props.operacaoid, history);
  const { notaLida, lerNotaFiscal } = LeitorNotaFiscal();
  const [codeReader, setCodeReader] = React.useState(new BrowserMultiFormatReader());
  const [abrirCompartilhar, setAbrirCompartilhar] = useState(false);
  const [abrirEncerrar, setAbrirEncerrar] = useState(false);
  const [abrirEncerrarDois, setAbrirEncerrarDois] = useState(false);
  const [dadosOperacao, setDadosOperacao] = useState<any>([]);
  const [operacaoCarregada, setOperacaoCarregada] = useState(false);
  const [horaInicio, setHoraInicio] = useState(new Date());
  const [horaFim, setHoraFim] = useState(new Date());
  const [temHoraFim, setTemHoraFim] = useState(false);
  const [tempoOperacao, setTempoOperacao] = useState("...");
  const [valorEstrela, setValorEstrela] = useState(0);
  const [textoEstrela, setTextoEstrela] = useState("Selecione");
  const [comentario, setComentario] = useState("");
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [showBrowserCamera, setShowBrowserCamera] = useState(false);
  const [showCarregando, setShowCarregando] = useState(false);
  const [notasAdicionais, setNotasAdicionais] = useState<any>([]);
  const [streamData, setStreamData] = useState<MediaStream>();
  const [clienteBeneficios, setClienteBeneficios] = useState<any>([]);
  const [showAlertInstrucao, setShowAlertInstrucao] = useState(false);
  const [showEncerrando, setShowEncerrando] = useState(false);
  const [showAlertOpcaoNota, setShowAlertOpcaoNota] = useState(false);


  const inputRef = useRef<any>(null);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);
  const [codigoBarras, setCodigoBarras] = React.useState<string>();

  const [exibeTexto, setexibeTexto] = useState("");
  const [front, setFront] = useState(false);

  const openScanner = async () => {

    if (isPlatform("mobileweb") || isPlatform("desktop")) {
      setShowBrowserCamera(true);
    }
    else {

      const opt: BarcodeScannerOptions = {
        formats: 'CODE_128',
        prompt: 'Escaneie o codigo de barras',
        showTorchButton : true
      }

      const data: any = await BarcodeScanner.scan(opt);

      if (!data.cancelled) {
        setShowCarregando(true);
        registrarNotaFiscal(data.text);
      }
    }
  };

  let params: any = useParams();

  useEffect(() => {
    console.log(props);
    carregarOperacao(params);
  }, [params]);

  var estabelecimento = {
    nome: params.estabelecimento,
    parceiro: false,
    endereco: "Rua Selvin Ln, 1035, São Paulo, SP",
    horarios: [
      { dia: "segunda-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "terça-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "quarta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "quinta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "sexta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "sábado", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "domingo", inicio: "8:00", fim: "16:00", aberto: false },
    ],
    medias: {
      historico: "45 min",
      hoje: "1h 30min"
    }
  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };


  function tempoCorrido(horario: Date, dataFim?: Date) {
    var g = Math.ceil(((dataFim ? dataFim.getTime() : Date.now()) - horario.getTime()) / 1000 / 60);
    var tempoApurado = Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + 'min';
    return tempoApurado !== "0h00min" ? tempoApurado : "0h01min";
  }

  function carregarOperacao(params: any) {

    if (params.operacaoid != "" && params.operacaoid != undefined) {

      if (1 == 1) {

        setOperacaoCarregada(true);

        var token: string = "";
        var tkn = localStorage.getItem("token");
        if (typeof (tkn) === "string") { token = tkn; }

        const getoperacao = async () => {

          requestService(carregaOperacao.url + params.operacaoid, {
            method: carregaOperacao.method,
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
            history,
            (response: any) => {
              if (response.Item !== undefined) {
                if (history.location.pathname.match("/motorista/acompanhar/") && response.Item.status === "Concluido" && props.paginaPai === "roteiro") {
                  history.goBack();
                }

                console.log(response);
                setDadosOperacao(response);
                setHoraInicio(new Date(response.Item.dataInicio));
                let horaFim = response.Item.dataEncerramento ? true : false;
                setTemHoraFim(horaFim);
                setHoraFim(response.Item.dataEncerramento ? new Date(response.Item.dataEncerramento) : new Date());
                setTempoOperacao(tempoCorrido(new Date(response.Item.dataInicio), response.Item.dataEncerramento ? new Date(response.Item.dataEncerramento) : new Date()));
                setNotasAdicionais(response.Item.notasAdicionais || []);

                setClienteBeneficios(response.Item.clienteInstrucoes || []);

                if (response.Item.clienteInstrucoesMotorista !== "" && !horaFim)
                  setShowAlertInstrucao(false);//setShowAlertInstrucao(true);

                if (response.Item.photo) {
                  console.log(response.Item.photo);

                  let _photos: string[] = response.Item.photo;
                  loadPhotos(_photos);
                }
                else {
                  clearPhotos();
                }

              }
              setShowLoading(false);
            },
            (error: any) => {
              console.log(error);
              setShowLoading(false);
              if (error.status === 426) { // atualizar versao
                history.replace("/atualizar");
              }
              else {
                history.replace("/login/");
              }
            });
        }

        getoperacao();
      }
    }
    return "";
  }

  setInterval(() => setTempoOperacao(tempoCorrido(horaInicio)), 60000);

  function loadHorarios() {
    return estabelecimento.horarios.map((value, index) => {
      return (
        <div className="horario" key={index}>
          <IonLabel>{value.dia}</IonLabel>
          <IonLabel>{value.aberto ? value.inicio + " - " + value.fim : "Fechado"}</IonLabel>
        </div>);
    });

  }

  function encerrar() {
    const atualizar = async () => {
      requestService(encerrarOperacao.url, {
        method: encerrarOperacao.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify({
          "id": params.operacaoid,
          "avaliacao": valorEstrela.toString(),
          "comentario": comentario.toString() ? comentario.toString() : "Sem comentário."
        }),
        credentials: 'include'
      },
        history,
        (response: any) => {
          if (response.message) {
            console.log(response);
            setShowEncerrando(false);
            history.replace('/motorista/roteiro');
            //history.goBack();
          }
        },
        (error: any) => {
          console.log(error);
          //history.replace("/login/");
          history.replace('/motorista/roteiro');
          //history.goBack();
        });
    }

    if (valorEstrela !== 0) {
      setAlertaPreenchimento(false);
      setShowEncerrando(true);
      atualizar();
    }
    else {
      setAlertaPreenchimento(true);
      setShowEncerrando(false);
    }
  }

  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  function formatarData(data: Date) {
    return (adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + "/" + data.getFullYear().toString().substr(2));
  }

  function formatarDataHora(data: Date) {
    return adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + " " + ("0" + data.getHours().toString()).substr(-2) + ":" + ("0" + data.getMinutes().toString()).substr(-2);
  }

  function ajustaTextoEstrela(value: any) {
    if (value === 1) setTextoEstrela("Muito ruim");
    if (value === 2) setTextoEstrela("Ruim");
    if (value === 3) setTextoEstrela("Normal");
    if (value === 4) setTextoEstrela("Bom");
    if (value === 5) setTextoEstrela("Excelente");
  }

  function startCamera() {
    if (arrayDevices.length == 0) {
      console.log("loadDevices");
      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .then(getStream)
        .catch((err) => { console.log(err); });
    } else {
      getStream();
    }
  }

  function cancelarScan() {
    if (codeReader) {
      codeReader.stopAsyncDecode();
      codeReader.stopContinuousDecode();
    }

    console.log(streamData);
    if (streamData) {
      streamData.getVideoTracks().forEach((valor: MediaStreamTrack) => {
        console.log(valor);
        valor.stop();
      });
    }

  }

  function getStream() {
    if (stream2) {
      stream2.getTracks().forEach(function (track: any) {
        console.log("getTracks");
        track.stop();
      });
    }

    //document.getElementById('flip-button').onclick = function() { setFront(!front); };

    const constraints = {
      audio: false,
      video: {
        width: 1024,
        height: 576,
        facingMode: (front ? "user" : "environment")
        //facingMode: { exact: "environment" }

        //deviceId: { exact: arrayDevices[selectedCamera] },
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch((err) => { console.log(err) });
  }

  function gotStream(stream: MediaStream) {
    setStreamData(stream);
    console.log("gotStream");
    const videoElement = document.getElementById("video") as HTMLVideoElement;
    stream.getVideoTracks().forEach((valor: MediaStreamTrack) => { console.log(valor); })

    videoElement.srcObject = stream;
    codeReader.decodeFromStream(stream, videoElement, (result: any) => {
      if (result) {
        codeReader.stopAsyncDecode();
        codeReader.stopContinuousDecode();
        stream.getVideoTracks().forEach((valor: MediaStreamTrack) => {
          console.log(valor);
          valor.stop();
        });
        registrarNotaFiscal(result.text.toString());
        //setShowBrowserCamera(false);
      } else {

      }
    }).catch((err) => { console.log(err) });

  }

  function gotDevices(deviceInfos: any) {
    console.log("getDevices");
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === "videoinput") {
        arrayDevices.push(deviceInfo.deviceId);
      }
    }
  }

  function registrarNotaFiscal(valor: any) {

    let objeto = { "notaFiscal": valor.toString(), "tipoOperacao": "Indefinida" };

    const registrar = () => {
      requestService(incluiNotaFiscal.url.replace("{id}", params.operacaoid.replace(/"#"/g, "%23")), {
        method: incluiNotaFiscal.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objeto),
        credentials: 'include'
      },
        history,
        (response: any) => {
          let objeto = { "notaFiscal": valor.toString(), "tipoOperacao": "Indefinida" };
          let incremento = notasAdicionais;
          incremento.push(objeto);
          setNotasAdicionais(incremento);
          Toast.show({
            text: "Sucesso na inclusão da nota",
            duration: "long"
          });
          setShowBrowserCamera(false);
          setShowModalCodigoBarrasDigitar(false);
          setShowCarregando(false);
          //setexibeTexto(JSON.stringify(response));
        },
        (error: any) => {
          Toast.show({
            text: "Erro na inclusão da nota",
            duration: "long"
          });
          setShowBrowserCamera(false);
          setShowCarregando(false);
        });
    }

    registrar();
  }

  return (

    <IonPage className="Acompanhar">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          {/*carregarOperacao()*/}
          <IonButtons slot="start" hidden={params.paginaPai == 'roteiro'}>
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonButtons slot="start" hidden={params.paginaPai != 'roteiro'}>
            <IonBackButton icon={flag} text=''></IonBackButton>
          </IonButtons>
          <IonItem lines="none" color="primary">
            <IonLabel color="dark" style={{ fontSize:"18px" }}>{dadosOperacao.Item ? dadosOperacao.Item.clienteNome : ""}</IonLabel>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <IonModal isOpen={showCarregando} cssClass='my-custom-class camera'>
          <IonLabel className="helper"><b>Aguarde</b>: carregando a nota fiscal</IonLabel>
        </IonModal>

        <IonModal isOpen={showModalCodigoBarrasDigitar} onDidPresent={() => { inputRef.current.setFocus(); }} onDidDismiss={() => { setShowModalCodigoBarrasDigitar(false); setCodigoBarras(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Incluir Nota Fiscal</IonTitle>
              <IonButtons slot="start" onClick={() => { cancelarScan(); setShowModalCodigoBarrasDigitar(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
              <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap" position="stacked">Informe a <b>chave de acesso</b> (código de barras) na <b>nota</b>:</IonLabel>
              <IonTextarea ref={(ref) => inputRef.current = ref} rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="Utilize o leitor de código de barras ou digite" onIonChange={e => space(e.detail.value!, 4)}></IonTextarea>
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' disabled={!botaoAtivo} onClick={() => { registrarNotaFiscal((codigoBarras || "").replace(/ /g, "")) }}>Registrar Nota!</IonButton>
              <IonButton expand="block" fill="clear" color="tertiary" onClick={() => { openScanner() }}>Utilizar câmera do celular</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showBrowserCamera} onDidDismiss={() => { setShowBrowserCamera(false); cancelarScan(); }} cssClass='my-custom-class camera' onDidPresent={() => { startCamera() }}>
          <video hidden={!showBrowserCamera} style={{ display: "flex", flexDirection: "column" }} id="video" autoPlay muted playsInline></video>
          <IonLabel className="helper">Aponte a camera para o <b>código de barras</b> que será feita a leitura.</IonLabel>
          {/*
          <IonItem>
            <IonLabel hidden={false}>{notaLida}</IonLabel>
          </IonItem>
          */}
          <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {/*<IonButton fill="clear" slot="end" color="tertiary" onClick={() => {registrarNotaFiscal("12345646545644252345324523452345432523452344324342654");}}>input</IonButton>*/}
            {/*<IonButton fill="solid" slot="end" color="tertiary" onClick={() => {cancelarScan();lerNotaFiscal();}}>Ler Chave de Acesso da Nota</IonButton>*/}
            <IonButton fill="solid" slot="end" color="tertiary" onClick={() => { setFront(!front); startCamera(); }}>Virar camera</IonButton>
            <IonButton fill="clear" slot="end" color="tertiary" onClick={() => { cancelarScan(); setShowBrowserCamera(false); }}>Cancelar</IonButton>
          </div>
        </IonModal>
        <IonModal isOpen={abrirEncerrar} cssClass="my-custom-class Compartilhar" onDidDismiss={() => setAbrirEncerrar(false)}>
          <IonHeader className="headerCompartilhar">
            <IonToolbar color="primary" onClick={() => setAbrirEncerrar(false)}>
              <IonTitle>Encerramento</IonTitle>
              <IonButtons slot="start">
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div><br /></div>
            <IonItemDivider>
              <IonLabel>
                Avaliar cliente
              </IonLabel>
            </IonItemDivider>
            <div className="avaliarComentar">
              <div className="avaliacaoEstrela">
                <BeautyStars
                  value={valorEstrela}
                  onChange={value => { setValorEstrela(value); ajustaTextoEstrela(value); setAbrirEncerrarDois(true); }}
                />
              </div>
              <div style={{ textAlign: 'center' }}><IonLabel>{textoEstrela}</IonLabel></div>
            </div>
          </IonContent>
        </IonModal>
        <IonModal isOpen={abrirEncerrarDois} cssClass="my-custom-class Compartilhar" onDidDismiss={() => setAbrirEncerrarDois(false)}>
          <IonHeader className="headerCompartilhar">
            <IonToolbar color="primary" onClick={() => setAbrirEncerrarDois(false)}>
              <IonTitle>Encerramento</IonTitle>
              <IonButtons slot="start">
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div><br /></div>
            <IonItem lines="full" style={{ "margin-bottom": "20px" }}>
              <IonLabel>
                Avaliação
              </IonLabel>
              <IonIcon hidden={!alertaPreenchimento} src={alertCircle} color="danger" slot="end" />
              <IonNote hidden={!alertaPreenchimento} color="danger" slot="end" style={{ "font-size": "14px" }} >Campo obrigatório</IonNote>
            </IonItem>
            <div className="avaliarComentar">
              <div className="avaliacaoEstrela">
                <BeautyStars
                  value={valorEstrela}
                  onChange={value => { setAlertaPreenchimento(false); setValorEstrela(value); ajustaTextoEstrela(value); setAbrirEncerrarDois(true); }}
                  editable={true}
                />
              </div>
              <div style={{ textAlign: 'center' }}><IonLabel>{textoEstrela}</IonLabel></div>
            </div>
            <IonItem lines="full" style={{ "margin-top": "20px" }}>
              <IonLabel>
                Como o cliente pode melhorar a operação?
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonTextarea rows={3} style={{ "margin": "5px", "padding": "5px", "border-width": "1px", "border-style": "solid", "border-color": "#e0e0e0", "margin-top": "10px", "margin-right": "10px", "background": "#f2f2f2" }} placeholder="Digite aqui sua sugestão" onIonChange={e => setComentario(e.detail.value!)}></IonTextarea>
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar >
              <IonButton expand="block" onClick={() => { encerrar() }} >Encerrar</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>
        <IonModal isOpen={abrirCompartilhar} cssClass="my-custom-class Compartilhar" onDidDismiss={() => setAbrirCompartilhar(false)}>
          <div className="headerCompartilhar" onClick={() => setAbrirCompartilhar(false)}>
            <IonLabel className="titulo">Avaliações</IonLabel>
            <IonLabel className="fechar">
              <IonIcon icon={closeOutline} size="large"></IonIcon>
              <span>Fechar</span>
            </IonLabel>
          </div>
          <h1>Avaliações</h1>
          <div className="avaliacoes">
            <div className="divisaoAvaliacoes">
              <div className="avaliacao">
                <span>1</span>
                <div className="barra">
                  <div className="preenchimento" style={{ width: "10%" }}></div>
                </div>
              </div>
              <div className="avaliacao">
                <span>2</span>
                <div className="barra">
                  <div className="preenchimento" style={{ width: "20%" }}></div>
                </div>
              </div>
              <div className="avaliacao">
                <span>3</span>
                <div className="barra">
                  <div className="preenchimento" style={{ width: "30%" }}></div>
                </div>
              </div>
              <div className="avaliacao">
                <span>4</span>
                <div className="barra">
                  <div className="preenchimento" style={{ width: "40%" }}></div>
                </div>
              </div>
              <div className="avaliacao">
                <span>5</span>
                <div className="barra">
                  <div className="preenchimento" style={{ width: "50%" }}></div>
                </div>
              </div>
            </div>
            <div className="media">
              <h1>1,3</h1>
              <IonLabel>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={starHalf}></IonIcon>
              </IonLabel>
              <IonLabel>(153)</IonLabel>
            </div>
          </div>
          <h1>Avaliar e comentar</h1>
          <div className="avaliarComentar">
            <IonLabel>Compartlihe sua experiência de hoje e ajude outros motoristas</IonLabel>
            <IonTextarea placeholder="Digite aqui sua avaliação"></IonTextarea>
            <div className="avaliacaoEstrela">
              <IonIcon icon={starOutline} size="large"></IonIcon>
              <IonIcon icon={starOutline} size="large"></IonIcon>
              <IonIcon icon={starOutline} size="large"></IonIcon>
              <IonIcon icon={starOutline} size="large"></IonIcon>
              <IonIcon icon={starOutline} size="large"></IonIcon>
            </div>
          </div>
          <h1>Comentários</h1>
          <IonList className="comentarios">
            <IonItem lines="full">
              <IonIcon src={iconTempo} slot="start" size="large" />
              <div className="content">
                <div className="cabecalho">
                  <IonLabel className="usuario">Anônimo</IonLabel>
                  <IonLabel className="data">novembro de 2020</IonLabel>
                </div>
                <span className="comentario">
                  Local não tem respeito pelo motorista, não há estrutura, obrigam chapa
                </span>
              </div>
            </IonItem>
          </IonList>

          <div className="maisComentarios">
            <IonButton style={{ alignSelf: "center" }}>Mais Comentários</IonButton>
          </div>

        </IonModal>
        <IonItem lines='none' color='tertiary' style={{padding:"0px",height:"40px"}}><IonLabel style={{ padding:"0px",margin:"0px",fontSize:"14px",height:"30px"}}><div style={{textAlign:'center'}}>{dadosOperacao.Item && dadosOperacao.Item.clienteParceiroSm?"Cliente utiliza Saidamax":"Cliente não utiliza Saidamax"}</div></IonLabel></IonItem>
        <div className="header">
          <div className="info">
            <IonIcon src={flagOutline}></IonIcon>
            <div>
              <IonLabel>Chegada</IonLabel>
              <IonLabel>{dadosOperacao.Item ? ("0" + new Date(dadosOperacao.Item.dataInicio).getHours().toString()).substr(-2) + ":" + ("0" +
                new Date(dadosOperacao.Item.dataInicio).getMinutes().toString()).substr(-2) : ""}</IonLabel>
            </div>
          </div>
          <div className="info">
            <IonIcon src={!temHoraFim ? hourglassOutline : flag}></IonIcon>
            <div hidden={temHoraFim}>
              <IonLabel>Aguardando</IonLabel>
              <IonLabel>{tempoOperacao}</IonLabel>
            </div>
            <div hidden={!temHoraFim}>
              <IonLabel>Término</IonLabel>
              <IonLabel>{dadosOperacao.Item ? ("0" + new Date(dadosOperacao.Item.dataEncerramento).getHours().toString()).substr(-2) + ":" + ("0" +
                new Date(dadosOperacao.Item.dataEncerramento).getMinutes().toString()).substr(-2) : ""}</IonLabel>
            </div>
          </div>
        </div>
        <IonList>
          <IonListHeader lines="full">
            <IonLabel>Operação</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon icon={cubeOutline} slot="start" />
            <div className="contentList">
              <h1>Tipo da operação</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.tipoOperacao ? dadosOperacao.Item.tipoOperacao : "não identificado"}</h2>
              <h2>{exibeTexto}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Status</h1>
              <h2>{dadosOperacao.Item ? dadosOperacao.Item.status : ""}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={leafOutline} slot="start" />
            <div className="contentList">
              <h1>Tipo da carga</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.tipoCarga ? dadosOperacao.Item.tipoCarga : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={timeOutline} slot="start" />
            <div className="contentList">
              <h1>Tempo de permanência</h1>
              <div className="horario">
                <IonLabel>Chegada</IonLabel>
                <IonLabel>{dadosOperacao.Item ? formatarDataHora(new Date(dadosOperacao.Item.dataInicio)) : ""}</IonLabel>
              </div>
              <div className="horario">
                <IonLabel>Término</IonLabel>
                <IonLabel>{dadosOperacao.Item && dadosOperacao.Item.dataEncerramento ? formatarDataHora(new Date(dadosOperacao.Item.dataEncerramento)) : ""}</IonLabel>
              </div>
              <div className="horario">
                <IonLabel>Duração</IonLabel>
                <IonLabel>{tempoOperacao}</IonLabel>
              </div>
            </div>
          </IonItem>

          <IonListHeader lines="full" hidden={dadosOperacao.Item && dadosOperacao.Item.clienteInstrucoesMotorista ? false : true}>
            <IonLabel>Instruções de carga e descarga</IonLabel>
          </IonListHeader>
          <IonItem lines="none" hidden={dadosOperacao.Item && dadosOperacao.Item.clienteInstrucoesMotorista ? false : true}>
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <IonTextarea readonly={true} style={{ "--background": "#eeeeee" }} rows={6} value={dadosOperacao.Item && dadosOperacao.Item.clienteInstrucoesMotorista ? dadosOperacao.Item.clienteInstrucoesMotorista : "Não informado pelo cliente."}></IonTextarea>
            </div>
          </IonItem>



          <IonListHeader lines="full" hidden={clienteBeneficios.length < 1}>
            <IonLabel>Benefícios</IonLabel>
          </IonListHeader>
          {clienteBeneficios.map((instrucao: any, index: any) => (
            <IonItem lines="none" hidden={temHoraFim}>
              <IonIcon src={thumbsUpOutline} slot="start" />
              <div className="contentList">
                <h2>{instrucao || ''} </h2>
              </div>
            </IonItem>
          ))}

          <IonListHeader lines="full" style={{ "display": "block" }}>
            <IonLabel>Notas Fiscais</IonLabel>
            <IonItem lines="none" hidden={temHoraFim}>
              <IonIcon src={addCircle} color="primary" onClick={() => { setShowAlertOpcaoNota(true);/*setShowModalCodigoBarrasDigitar(true);*/ }}></IonIcon>
            </IonItem>
          </IonListHeader>
          <IonItem lines="none" hidden={notasAdicionais.length > 0}>
            <IonIcon src={receiptOutline} slot="start" />
            <div className="contentList">
              <h2>Nenhuma nota registrada</h2>
            </div>
          </IonItem>
          <IonItem lines="none" hidden={dadosOperacao.Item ? (dadosOperacao.Item.operacaoChaveAcesso === "0" ? true : false) : false}>
            <IonIcon src={receiptOutline} slot="start" />
            <div className="contentList">
              <h1>Nota Fiscal</h1>
              <h2>{dadosOperacao.Item ? dadosOperacao.Item.operacaoChaveAcesso.substr(25, 9) : ""} </h2>
            </div>
            <IonIcon slot="end" color="primary" src={eye} onClick={() => { window.open("https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=" + (dadosOperacao.Item ? dadosOperacao.Item.operacaoChaveAcesso : ""), '_blank') }} ></IonIcon>
          </IonItem>
          {notasAdicionais.map((notasValue: any, index: any) => (
            <IonItem lines="none">
              <IonIcon src={receiptOutline} slot="start" />
              <div className="contentList">
                <h1>Nota Fiscal</h1>
                <h2>{notasValue ? notasValue.notaFiscal.substr(25, 9) : ""} </h2>
              </div>
              <IonNote hidden={true} slot="end">{notasValue ? notasValue.tipoOperacao : ""}</IonNote>
              <IonIcon slot="end" color="primary" src={eye} onClick={() => { window.open("https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=" + (notasValue ? notasValue.notaFiscal : ""), '_blank') }} ></IonIcon>
            </IonItem>
          ))}

          <IonListHeader lines="full">
            <IonLabel>Motorista</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.motoristaNome ? dadosOperacao.Item.motoristaNome : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={cardOutline} slot="start" />
            <div className="contentList">
              <h1>Documento</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.motoristaDocumento ? dadosOperacao.Item.motoristaDocumento : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={carOutline} slot="start" />
            <div className="contentList">
              <h1>Veículo</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.veiculo ? dadosOperacao.Item.veiculo : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonListHeader lines="full">
            <IonLabel>Local</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon icon={navigate} slot="start" />
            <div className="contentList">
              <h1>Endereço</h1>
              <h2>{dadosOperacao.Item ? dadosOperacao.Item.clienteEndereco : ""}</h2>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://www.google.com/maps/search/?api=1&query=" + (dadosOperacao.Item ? dadosOperacao.Item.clienteLatitude.concat(",").concat(dadosOperacao.Item.clienteLongitude).concat("&query_place_id=").concat(dadosOperacao.Item.clienteCodigo || "") : "")}><img src={maps} alt="maps" /></a>
                <a target='_blank' href={"https://waze.com/ul?ll=" + (dadosOperacao.Item ? dadosOperacao.Item.clienteLatitude.concat("%2C").concat(dadosOperacao.Item.clienteLongitude) : "") + "&navigate=yes"}><img src={waze} alt="waze" /></a>
              </div>
            </div>
          </IonItem>
          <IonItem lines="none" hidden={true}>
            <IonIcon src={iconHorario} slot="start" />
            <div className="contentList">
              <h1>Horários de Funcionamento</h1>
              {loadHorarios()}
            </div>
          </IonItem>
          <IonItem lines="full" hidden={true}>
            <IonIcon src={iconTempo} slot="start" />
            <div className="contentList">
              <h1>Tempos Médios de Carga/Descarga</h1>
              <div className="horario">
                <IonLabel>Histórico</IonLabel>
                <IonLabel>{estabelecimento.medias.historico}</IonLabel>
              </div>
              <div className="horario">
                <IonLabel>Hoje</IonLabel>
                <IonLabel>{estabelecimento.medias.hoje}</IonLabel>
              </div>
            </div>
          </IonItem>
          <IonListHeader lines="full">
            <IonLabel>Fotos</IonLabel>
            <IonItem lines="none" hidden={temHoraFim}>
              <IonIcon src={addCircle} color="primary" onClick={async () => { await takePhoto(params.operacaoid); }}></IonIcon>
            </IonItem>
          </IonListHeader>
          <IonItem lines="none" hidden={photos.length > 0}>
            <IonIcon src={camera} slot="start" />
            <div className="contentList">
              <h2>Nenhuma foto registrada</h2>
            </div>
          </IonItem>
          <IonItem lines="none">

            <IonGrid>
              <IonRow>
                {photos.map((photo, index) => (
                  <IonCol size="6" key={index}>
                    <div>
                      <a target='_blank' href={photo.webviewpath?.replace("small", "big")}>
                        <IonImg src={photo.webviewpath} />
                      </a>
                    </div>
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>

          </IonItem>
        </IonList>
        <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={[{
            text: 'Encerrar Operação',
            icon: checkmarkCircle,
            handler: () => {
              setValorEstrela(0);
              setAlertaPreenchimento(false);
              setAbrirEncerrarDois(true);
            }
          }, {
            text: 'Tirar Foto',
            icon: camera,
            handler: async () => {
              await takePhoto(params.operacaoid);
            }
          }, {
            text: 'Incluir nota',
            icon: receipt,
            handler: () => {
              setShowAlertOpcaoNota(true);
              /*setShowModalCodigoBarrasDigitar(true);*/
            }
          }, {
            text: 'Cancelar',
            icon: close,
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }]}
        >
        </IonActionSheet>
        <IonAlert
          isOpen={showAlertInstrucao}
          onDidDismiss={() => setShowAlertInstrucao(false)}
          cssClass='my-custom-class'
          header={'Aviso!'}
          subHeader={'Fique atento as instruções de carga e descarga!'}
          message={''}
          buttons={['OK']}
          backdropDismiss={false}
        />
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
        <IonLoading
          isOpen={showEncerrando}
          backdropDismiss={false}
          message={'Encerrando...'}
        />
        <IonAlert
          isOpen={showAlertOpcaoNota}
          onDidDismiss={() => setShowAlertOpcaoNota(false)}
          cssClass="my-custom-class"
          header={'Incluir nota'}
          message={'Como quer inserir a nota?'}
          buttons={[
            { text: 'Digitar', cssClass: 'alert-button', handler: () => { setShowModalCodigoBarrasDigitar(true); } },
            { text: 'Utilizar camera', cssClass: 'alert-button-confirm', handler: () => { openScanner(); } }]}
        />
      </IonContent>
      <IonFooter className="ion-no-border" hidden={temHoraFim}>
        <IonToolbar className="acompanhar" >
          <IonButton size="small" onClick={() => { setShowActionSheet(true); /* setAbrirEncerrar(true)*/ }} expand="block">Ações</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );


};

export default Acompanhar;