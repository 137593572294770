import { IonContent, IonPage, IonFab, IonFabButton, IonSlides, IonButton, IonSlide, IonLabel, IonSegment, IonSegmentButton, IonItem, IonIcon, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonModal, useIonViewDidEnter, useIonViewWillLeave, IonInfiniteScroll, IonInfiniteScrollContent, IonToast, useIonViewDidLeave, useIonViewWillEnter, IonAlert, IonButtons, IonBackButton, IonHeader, IonToolbar, IonTitle, IonFooter, IonList, IonItemDivider, IonLoading } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { add, arrowBack, car, documentOutline, remove, trash } from 'ionicons/icons';
import './Placa.css';
import { useHistory } from 'react-router-dom';
import PlacaCad from '../PlacaCad/PlacaCad';
import { placaExcluir, usuarioCarregar, requestService } from '../../../../../Utils/Services';

const Placa: React.FC = () => {
  const history = useHistory();
  const [aba, setAba] = useState("proximos");
  const [PlacaPages, setPlacaPages] = useState([
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
    { ordem: 0, titulo: "", descricao: "", caminho: "" },
  ]);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayPlacas, setarrayPlacas] = useState<any>([]);
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [placa, setPlaca] = useState("");
  const [showExcluirPlaca, setShowExcluirPlaca] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [chamarServico, setChamarServico] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showExcluding, setShowExcluding] = useState(false);

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";
  });

  useIonViewWillLeave(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";

  })

  let slides = useRef(document.createElement('ion-slides'));

  const loadItens2 = () => {

    if (arrayPlacas === undefined || arrayPlacas.length === 0) {
      return (
        <IonItem lines="none" color="light">
          <IonLabel>Não há veíuclos</IonLabel>
        </IonItem>
      );
    }

    return arrayPlacas.map((value: any, index: any, arr: any) =>
      <IonItem color="light" lines="none">
        <IonIcon color='medium' slot="start" icon={car} />
        <IonLabel>{value}</IonLabel>
        <IonIcon color='medium' slot="end" icon={trash} onClick={() => { setPlaca(value); setMensagem("Excluir o veículo <strong>" + value + "</strong>?"); setShowExcluirPlaca(true) }} />
      </IonItem>
    );

  }

  function carregarPlacas() {
    const consultar = async () => {
      requestService(usuarioCarregar.url, {
        method: usuarioCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          if (response.placas) {
            window.localStorage.setItem("carros", JSON.stringify(response.placas));
          }
          else {
            window.localStorage.removeItem("carros");
          }
          setarrayPlacas(response.placas);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    }

    if (chamarServico) {
      setChamarServico(false);
      consultar();
    }
  }

  async function closeModal() {
    setShowDetalhes(false);
    setChamarServico(true);
    carregarPlacas();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle>Veículos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {carregarPlacas()}
        <IonModal isOpen={showDetalhes} onDidDismiss={() => { setShowDetalhes(false); }}>
          <PlacaCad closeAction={closeModal}></PlacaCad>
        </IonModal>

        <IonItemDivider color="light">
          <IonLabel className='placa'>
            Lista de veículos cadastrados
          </IonLabel>
        </IonItemDivider>
        {loadItens2()}




        <IonAlert
          isOpen={showExcluirPlaca}
          onDidDismiss={() => setShowExcluirPlaca(false)}
          header={'Exclusão de veículo'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',

              handler: () => {
                setShowExcluding(true);

                const objectRequest = {
                  placa: placa
                }

                let cpf = window.localStorage.getItem("cpf") + '';

                requestService(placaExcluir.url.replace("{cpf}", cpf), {
                  method: placaExcluir.method,
                  body: JSON.stringify(objectRequest),
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    setChamarServico(true);
                    carregarPlacas();
                    setTextToast(response.message);
                    setShowSucesso(true);
                    setShowExcluding(false);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                    setShowExcluding(false);
                  });
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showExcluding}
          onDidDismiss={() => { setShowExcluding(false); }}
          message={'Excluindo...'}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); }}
          message={'Excluído!'}
          duration={1200}
          animated={false}
        />

        <IonAlert
          isOpen={showSucesso}
          onDidDismiss={() => setShowSucesso(false)}
          header={'Sucesso'}
          message={textToast}
          buttons={['OK']}
        />

        <IonAlert
          isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar color="light" >
          <IonButton onClick={() => setShowDetalhes(true)} expand="block" type="submit">Incluir</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Placa;
