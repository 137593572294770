import { IonContent, IonPage, IonButton, IonToast, IonHeader, IonToolbar, IonTitle, IonFooter, IonListHeader, IonAvatar, IonList, IonItem, IonLabel, IonText, IonIcon, IonButtons, IonModal, useIonViewWillEnter, useIonViewDidEnter, IonNote, IonAlert } from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import './AvaliacaoAPP.css';
import logo from './imgs/icon.png';

interface UserDetailPageProps extends RouteComponentProps<{ }> { }

const AvaliacaoAPP: React.FC<UserDetailPageProps> = ({ match }) => {

  const history = useHistory();

  return (
    <IonPage>
      <IonContent fullscreen={true} className="periodoAvaliacao">
        <div slot="fixed" >
          <img src={logo} alt="Logo" width={"100px"} />
          <section></section>

          <section>
            <header><b>Estamos começando...</b></header>
            <IonLabel>Obrigado por fazer parte deste início do Saidamax!</IonLabel>
          </section>

          <section>
            <IonLabel hidden={true}>Durante esta fase de avaliação não há custos. E o melhor, quando começarmos para valer, daremos para você um <b>benefício especial</b> por estar conosco desde o começo.</IonLabel>
          </section>

          <section></section>

          <IonButton color="primary" onClick={() => { history.replace("/motorista/roteiro"); }}>OK</IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default AvaliacaoAPP;