import { IonContent, IonPage, IonButton, IonLabel, IonInput, IonHeader, IonList, IonItem, IonToolbar, IonTitle, IonFooter, IonButtons, IonIcon, IonAlert, IonBackButton, IonLoading } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './PlacaCad.css';
import '@capacitor-community/http';
import { placaCadastrar, requestService } from '../../../../../Utils/Services';
import { arrowBack } from 'ionicons/icons';

type MyModalProps = {
  closeAction: Function;
}

const PlacaCad: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory();

  const [textToast, setTextToast] = useState("");
  const [placa, setPlaca] = useState("");
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  function cadastrar() {
    let mensagem = '';

    if (placa.length != 7) {
      setTextToast("O campo Placa é obrigatório.");
      setShowErro(true);
    }
    else {
      setShowIncluirUsuario(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={(e) => { props.closeAction(); }} />
          </IonButtons>
          <IonTitle>Inclusão de Veículo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Placa do veículo*</IonLabel>
            <IonInput placeholder="Digite a placa do veículo" value={placa} required maxlength={7} onIonInput={(e: any) => setPlaca(e.target.value)}></IonInput>
          </IonItem>
        </IonList>

        <IonAlert isOpen={showIncluirUsuario}
          onDidDismiss={() => setShowIncluirUsuario(false)}
          header={'Cadastro de veículo'}
          message={'Você <strong>confirma</strong> inclusão?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                const objectRequest = {
                  placa: placa
                }

                let cpf = window.localStorage.getItem("cpf") + '';

                requestService(placaCadastrar.url.replace("{cpf}", cpf), {
                  method: placaCadastrar.method,
                  body: JSON.stringify(objectRequest),
                  headers: [
                    ["token", window.localStorage.getItem("token")]
                  ],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    if (response.placas) {
                      window.localStorage.setItem("carros", JSON.stringify(response.placas));
                    }
                    setTextToast("Cadastro realizado com sucesso.");
                    setShowSucesso(true);
                    setShowLoading(false);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                    setShowLoading(false);
                  });
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          message={'Incluindo...'}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); setPlaca(""); props.closeAction(); }}
          message={'Sucesso!'}
          duration={1200}
          animated={false}
        />

        <IonAlert isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ({ closeAction }: { closeAction: Function }) => (
  <PlacaCad closeAction={closeAction}>
  </PlacaCad>
)
