import { IonContent, IonPage, IonSlides, IonButton, IonSlide, IonLabel, IonSegment, IonSegmentButton, IonItem, IonIcon, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonModal, useIonViewWillEnter, IonListHeader, IonList, IonText, IonHeader, IonToolbar, IonButtons, IonTitle, IonImg, IonRow, IonCol } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { pin, wifi, wine, warning, walk, arrowBackOutline, arrowBackCircleOutline, handRight, heart, share, musicalNote, star } from 'ionicons/icons';
import './Compartilhado.css';
import { Link, useHistory } from 'react-router-dom';
import iconVerified from "./imgs/certificado.svg";
import Acompanhar from '../Acompanhar/Acompanhar';
import { setupConfig } from '@ionic/react'

const Compartilhado: React.FC = () => {
  // console.log("Loadging: Itinerario")
  const history = useHistory();
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={handRight} />
            </IonButton>
          </IonButtons>
          <IonTitle>Apoio</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {/* <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>parceiro</IonCardSubtitle>
            <IonCardTitle>Assistência Jurídica</IonCardTitle  >
          </IonCardHeader>
          <IonCardContent>
            <p>
              Na hora que você mais precisar de ajuda, a gente vai estar aqui pronto para te ajudar.
            </p>
            <p>
              E o melhor: <strong>sem custo antecipado!</strong>.
            </p>
            <IonItem lines="none">
              <IonButton fill="solid">Veja mais</IonButton>
              <IonIcon icon={heart} slot="end"></IonIcon>
              <IonIcon icon={share} slot="end"></IonIcon>
            </IonItem>
          </IonCardContent>
        </IonCard> */}

        <IonCard>
          <IonCardHeader>
            {/* <IonCardSubtitle>parceiro</IonCardSubtitle> */}
            <IonCardTitle>Em construção</IonCardTitle  >
          </IonCardHeader>
          <IonCardContent>
            <p>
              Em breve você terá um clube de vantagens com diversos benefícios exclusivos aqui no Saidamax.
            </p>
            <p>
              {/* E o melhor: <strong>o começo é totalmente grátis!</strong>. */}
            </p>
            <IonItem lines="none">
              {/* <IonButton fill="solid">Veja mais</IonButton> */}
              <IonIcon icon={heart} slot="end"></IonIcon>
              <IonIcon icon={share} slot="end"></IonIcon>
            </IonItem>
          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
};

export default Compartilhado;
