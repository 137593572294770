import { Toast } from "@capacitor/toast";

const URL_BASE = "https://ce89z01jak.execute-api.sa-east-1.amazonaws.com/prd"
//const URL_BASE = "https://e9gfr1dvgf.execute-api.sa-east-1.amazonaws.com/dev";
//const URL_BASE = "http://localhost:3000/dev";
//const URL_BASE = "http://localhost:3000/prd";

export const ambiente = "prd"

//Sobre Usuario
export const onboarding_all = { url: URL_BASE + "/onBoarding/v1?onBoarding=1", method: "GET" };
export const usuarioCadastrar = { url: URL_BASE + "/usuario", method: "POST" };
export const usuarioAlterar = { url: URL_BASE + "/usuario", method: "PUT" };
export const usuarioCarregar = { url: URL_BASE + "/usuario", method: "GET" };
export const usuarioAtualizarChaveContato = { url: URL_BASE + "/usuario", method: "PATCH" };
export const placaExcluir = { url: URL_BASE + "/usuario/{cpf}/veiculos", method: "DELETE" };
export const placaCadastrar = { url: URL_BASE + "/usuario/{cpf}/veiculos", method: "POST" };
export const confirmarCadastro = { url: URL_BASE + "/usuario/confirmar", method: "POST" };
export const logar = { url: URL_BASE + "/usuario/logar", method: "POST" };
export const deslogar = { url: URL_BASE + "/usuario/deslogar", method: "POST" };
export const confirmarLogin = { url: URL_BASE + "/usuario/logar/otp", method: "POST" };
export const verificaLogin = { url: URL_BASE + "/echoLogado", method: "GET" };
export const autenticado = { url: URL_BASE + "/autenticado", method: "POST" };

//Sobre Empresas
export const listarEmpresa = { url: URL_BASE + "/empresa", method: "GET" };

//Sobre Operacoes
export const iniciarOperacao = { url: URL_BASE + "/operacao", method: "POST" };
export const encerrarOperacao = { url: URL_BASE + "/operacao", method: "PUT" };
export const listaOperacao = { url: URL_BASE + "/operacao", method: "GET" };
export const carregaOperacao = { url: URL_BASE + "/operacao/", method: "GET" };
export const clienteCarregar = { url: URL_BASE + "/clientes/{id}", method: "GET" };

export const incluirOcorrenciaOperacao = { url: URL_BASE + "/operacao", method: "PATCH" };
export const incluiNotaFiscal = { url: URL_BASE + "/operacao/{id}/notas", method: "POST" };
export const iniciarOperacaoAvulsa = { url: URL_BASE + "/avulsas", method: "POST" };

//export const listaOperacaoCompartilhada = {url: URL_BASE+"/operacao/compartilhada", method: "GET"};

export async function getJSONObject(url: string) {
  const response = await fetch(url);
  const myJson = await response.json();
  return myJson;
}

export async function requestService(url: any, config: any, history: any, callbackSuccess: any, callbackError: any, redirectLogin?: boolean, getStatus?: boolean) {
  //console.log("call api");
  //console.log(url);
  //console.log(config);
  //console.log(history);

  const timeoutId = setTimeout(() => {
    Toast.show({
      text: 'Sua conexão está lenta. Isto pode demorar um pouco. Tente melhorar o sinal.',
      duration: "long"
    });
  }, 15000);

  // inclui a versão no cabecalho de todas as chamadas
  if (config !== undefined) {
    config.headers = [
      ["token", window.localStorage.getItem("token") || ""],
      ["versao", window.localStorage.getItem("versao") || ""]
    ];
  }

  return await fetch(url, config).then(res => {
    if (res.status === 426) {
      history.replace("/atualizar");
    } else if (res.status === 401 && (redirectLogin || redirectLogin === undefined)) {
      if (history)
        history.replace("/login");
      res.json().then(response => {
        response.status = res.status;
        callbackError(response);
      });
    } else if (res.status === 200 || res.status === 201) {
      res.json().then(response => {
        if (getStatus)
          response.status = res.status
        callbackSuccess(response);
      });
    } else {
      res.json().then(response => {
        response.status = res.status;
        callbackError(response);
      });
    }
  }).catch(async (err) => {
    await Toast.show({
      text: 'Sua conexão está muito lenta. Verifique seu sinal e tente novamente.',
      duration: "long"
    });
    err.saidamax = 444;
    callbackError(err);
  }).finally(() => { clearTimeout(timeoutId) });
}