import { IonContent, IonPage, IonSlides, IonButton, IonSlide, IonLabel, isPlatform, IonHeader, IonItem, IonImg } from '@ionic/react';
import React, { useRef, useState } from 'react';
import './Atualizar.css';
import { Link, useHistory } from 'react-router-dom';
import logo from './imgs/icon.png';

class Atualizar extends React.Component<any, any>{
  constructor(props: any) {
    super(props);

  }
  RetornarStore() {
    if (isPlatform("android")) {
      return "https://play.google.com/store/apps/details?id=com.saidamax.mobile&hl=pt_BR";
    }
    return "https://apps.apple.com/br/app/saidamax/id1234567890";
  }

  render() {
    return (
      <IonPage>
        <IonContent fullscreen={true} className="atualizar">
          <div slot="fixed" >
            <img src={logo} alt="Logo" width={"100px"} />
            <section></section>

            <section>
              <header><b>Atualização Saidamax</b></header>
              <IonLabel>Tamanho aproximado: 7 MB</IonLabel>
            </section>

            <section>
              <IonLabel>Para continuar utilizando Saidamax, instale a versão mais atual.</IonLabel>
            </section>

            <section></section>
            
            <IonButton color="primary" onClick={() => { window.open(this.RetornarStore(), "_blank"); }}>Atualizar</IonButton>
            
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default Atualizar;
