import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";


// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyD_4Cg9l_ptceZojWnKYVxMNLczpbtKgPU",
    authDomain: "saidamax-mobile.firebaseapp.com",
    projectId: "saidamax-mobile",
    storageBucket: "saidamax-mobile.appspot.com",
    messagingSenderId: "511297073293",
    appId: "1:511297073293:web:3183110e8a63ae21da72c4",
    measurementId: "G-8TGZYCNDSV"
  };

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app); 