import { IonContent, IonPage, IonSlides, IonButton, IonSlide, IonLabel, IonHeader, IonToolbar, IonTitle, IonFooter, IonImg, IonItem } from '@ionic/react';
import React, { useRef, useState } from 'react';
import './Onboarding.css';
import { Link, useHistory } from 'react-router-dom';
import { onboarding_all, requestService } from '../../../Utils/Services';

class Onboarding extends React.Component<any, any>{
  private slides: React.RefObject<any>;

  constructor(props: any) {
    super(props);

    this.slides = React.createRef();
    this.state = {
      count: 0,
      onboardingPages: [],
      textButton: "Próximo",
      classIrLogin: "",
    }

    requestService(
      onboarding_all.url,
      {
        method: onboarding_all.method
      },
      props.history,
      (response: any) => {
        this.setState({
          onboardingPages: response.onboarding
        });
      },
      (error: any) => console.log(error)
    );

    this.onClickEvent = this.onClickEvent.bind(this);
    this.onClickEvent2 = this.onClickEvent2.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.loadSlides = this.loadSlides.bind(this);
  }

  onClickEvent() {
    this.slides.current!.isEnd().then(
      (isEnd: any) => {
        if (isEnd) {
          this.props.history.replace('/primeiroacesso');
        } else {
          this.slides.current.slideTo(this.state.count + 1);
          this.setState({ count: this.state.count + 1 })
        }
      }
    );
  }

  onClickEvent2() {
    this.props.history.replace('/primeiroacesso');
  }

  nextSlide() {

    this.slides.current.isEnd().then(
      (isEnd: any) => {
        if (isEnd) {
          this.setState({
            textButton: "Próximo",
            classIrLogin: "invisivel"
          });
        } else {
          this.setState({
            textButton: "Próximo",
            classIrLogin: ""
          });
        }
      }
    );
  }

  loadSlides() {
    if (this.state.onboardingPages.length > 0)
      return (
        <IonSlides className={"OnboardingSlides"} pager={true} ref={this.slides} onIonSlideWillChange={this.nextSlide}>
          {this.state.onboardingPages.map((value: any, index: any) => (<IonSlide key={index}>
            <div style={{height:'450px'}}>
              <IonImg style={{ maxWidth: '250px', margin: '0 auto' }} src={value.caminho} alt="img" />
              <IonItem lines='none'>
                <IonLabel style={{ textAlign: 'center', fontSize: '24px', color: '#333333' }}><b>{value.titulo}</b></IonLabel>
              </IonItem>
              <IonItem lines='none'>
                <IonLabel className="ion-text-wrap" style={{ margin: '0 5% 0 5%', color: '#333333', textAlign: 'center' }}>{value.descricao}</IonLabel>
              </IonItem>
            </div>
          </IonSlide>))}
        </IonSlides>
      )
  }

  render() {
    return (
      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle style={{ textAlign: 'center', color: '#333333' }}><b>SAIDAMAX</b></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          {this.loadSlides()}


        </IonContent>
        <IonFooter className="ion-no-border">
          <IonButton style={{ padding: "0 5% 0 5%", margin: '0 0 25px 0', fontWeight: 'bold' }} size='large' expand='block' fill='solid' color='primary' onClick={this.onClickEvent}>{this.state.textButton}</IonButton>
          <IonButton style={{ padding: "0 5% 0 5%", margin: '0 0 10px 0', fontWeight: 'bold' }} size='large' expand='block' fill="clear" color="tertiary" onClick={this.onClickEvent2}>Ir para o início</IonButton>
        </IonFooter>
      </IonPage>
    );
  }
}

export default Onboarding;
